import * as React from 'react';

import ReactApexChart from 'react-apexcharts';

import { DailyProductionSpecs } from './dailyProductionSpecs';
import Interval from '../dashboard/interval';
import * as timeFunctions from '../controls/timeFunctions';
import formatDate from 'date-fns/format';

const styles = require('./dailyView.scss');

interface Props {
  dailyProductionSpecs: DailyProductionSpecs;
  dates: string[];
  handlePointClick: (date: string) => void;
}

class MixProductionGraph extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  getGraphOptions = () => {
    const showXAxis: boolean = !!this.props.dates.length;
    // Area and line graphs won't show for a single data point, so when there is only one data point we have to set the marker size so the data is seen on the graph
    const markerSizes: number[] = this.props.dates.length === 1 ? [5, 5, 5] : [5, 0, 0];
    return {
      chart: {
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            this.props.handlePointClick(this.props.dates[config.dataPointIndex]);
          }.bind(this),
          dataPointMouseEnter: function(event) {
            event.target.style.cursor = 'pointer';
          }
        }
      },
      colors: ['#53cfdb', '#94ccf0', '#5fb2e8'],
      title: {
        text: '# of Batches/hr, Batches Time & Wet Mixing Time',
        align: 'left',
        offsetX: 25,
        offsetY: 15,
        style: {
          fontSize: '1rem'
        }
      },
      noData: {
        text: 'No data for selected date range',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetY: -30,
        showForSingleSeries: true,
        markers: {
          onClick: undefined
        },
        onItemClick: {
          toggleDataSeries: false
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: [1, 0.7, 0.7],
        type: 'solid'
      },
      stroke: {
        width: [0, 0, 0]
      },
      markers: {
        size: markerSizes
      },
      xaxis: {
        type: 'category',
        position: 'top',
        offsetY: -40,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: showXAxis
        }
      },
      yaxis: [
        {
          seriesName: '# of batches/hr',
          title: {
            text: 'batches/hr',
            style: {
              fontSize: '0.75rem'
            }
          },
          decimalsInFloat: 0
        },
        {
          seriesName: 'Batches Time',
          opposite: true,
          title: {
            text: 'Time',
            rotate: 90,
            style: {
              fontSize: '0.75rem'
            }
          },
          labels: {
            formatter: timeFunctions.formatSeconds,
            show: showXAxis
          }
        },
        {
          seriesName: 'Batches Time',
          show: false
        }
      ],
      tooltip: {
        marker: {
          show: false
        },
        custom: function({ series, seriesIndex, dataPointIndex }) {
          const date = formatDate(this.props.dates[dataPointIndex], 'MM/dd');
          const batchesPerHour = series[seriesIndex][dataPointIndex];
          const averageYieldVariance = this.props.dailyProductionSpecs.averageYieldVariance[dataPointIndex].toFixed(2);
          const numberOfBatches = this.props.dailyProductionSpecs.numberOfBatches[dataPointIndex];
          const batchDuration = this.props.dailyProductionSpecs.batchDuration[dataPointIndex];
          const wetMixTime = this.props.dailyProductionSpecs.wetMixTime[dataPointIndex];
          const stdDevWetMixTime = this.props.dailyProductionSpecs.stdDevWetMixTime[dataPointIndex];
          const idleTime = this.props.dailyProductionSpecs.idleTime[dataPointIndex];
          return this.customProductionToolTip(
            date,
            batchesPerHour,
            averageYieldVariance,
            numberOfBatches,
            batchDuration,
            wetMixTime,
            stdDevWetMixTime,
            idleTime
          );
        }.bind(this)
      }
    };
  };

  customProductionToolTip = (
    date: string,
    batchesPerHour: number,
    averageYieldVariance: number,
    numberOfBatches: number,
    batchDuration: Interval,
    wetMixTime: Interval,
    stdDevWetMixTime: number,
    idleTime: Interval
  ) => {
    return `<div class=${styles.dailyToolTip}>
        <div class=${styles.dateRow}>${date}</div>
        <table>
          <tbody>
            <tr class=${styles.info}>
              <td>Average Batch Time:</td>
              <td>${timeFunctions.formatInterval(batchDuration)}
            </tr>
            <tr class=${styles.info}>
              <td>Average Wet Mixing Time:</td>
              <td>${timeFunctions.formatInterval(wetMixTime)}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Standard Deviation Wet Mixing:</td>
              <td>${timeFunctions.formatSeconds(stdDevWetMixTime)}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Average Mixer Idle Time:</td>
              <td>${timeFunctions.formatInterval(idleTime)}</td>
            </tr>
            <tr class=${styles.info}>
              <td># of Batches/hr:</td>
              <td>${batchesPerHour}</td>
            </tr>
            <tr class=${styles.info}>
              <td># of batches:</td>
              <td>${numberOfBatches}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Average Yield Variance:</td>
              <td>${averageYieldVariance}%</td>
            </tr>
          </tbody>
        </table>
      </div>`;
  };

  getGraphSeries = () => {
    const batchesPerHour = [];
    this.props.dailyProductionSpecs.batchesPerHour.forEach((value, index) => {
      if (value) {
        value = parseFloat(value.toFixed(2));
      }
      const date: string = formatDate(this.props.dates[index], 'MM/dd');
      batchesPerHour.push({ x: date, y: value });
    });
    const batchTime = [];
    this.props.dailyProductionSpecs.batchDuration.forEach((value, index) => {
      let seconds: number = null;
      if (value) {
        seconds = timeFunctions.intervalToSeconds(value);
      }
      const date: string = formatDate(this.props.dates[index], 'MM/dd');
      batchTime.push({ x: date, y: seconds });
    });
    const wetMixTime = [];
    this.props.dailyProductionSpecs.wetMixTime.forEach((value, index) => {
      let seconds: number = null;
      if (value) {
        seconds = timeFunctions.intervalToSeconds(value);
      }
      const date: string = formatDate(this.props.dates[index], 'MM/dd');
      wetMixTime.push({ x: date, y: seconds });
    });

    if (batchesPerHour.length === 0 && batchTime.length === 0 && wetMixTime.length === 0) {
      return [];
    } else {
      return [
        {
          name: '# of batches/hr',
          data: batchesPerHour
        },
        {
          name: 'Batches Time',
          type: 'area',
          data: batchTime
        },
        {
          name: 'Wet Mixing Time',
          type: 'area',
          data: wetMixTime
        }
      ];
    }
  };

  render() {
    const options = this.getGraphOptions();
    const series = this.getGraphSeries();
    return (
      <section className={styles.dailyGraphContainer}>
        <div className={styles.dailySectionTitle}>Concrete Mix Production</div>
        <ReactApexChart options={options} series={series} type="line" height="350" />
      </section>
    );
  }
}

export default MixProductionGraph;
