import * as React from 'react';

import ReactApexChart from 'react-apexcharts';

const styles = require('./airContentMetric.scss');

class AirContentMetric extends React.Component<{ value: number; avgValue: number }, { options: any }> {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 0,
              size: '85%',
              fill: '#0088be'
            },
            track: {
              background: '#e7e7e7',
              strokeWidth: '100%',
              margin: 0
            },
            dataLabels: {
              name: {
                offsetY: 25,
                fontSize: '1rem',
                color: '#c7c7c7',
                fontFamily: 'Roboto, sans-serif'
              },
              value: {
                offsetY: -30,
                fontSize: '3rem',
                color: '#0088be'
              }
            }
          }
        },
        labels: ['Percent Within Air Target'],
        colors: ['#0088be']
      }
    };
  }

  render() {
    return (
      <section className={styles.airContentContainer}>
        <ReactApexChart
          options={this.state.options}
          series={formatNumber(this.props.value)}
          type="radialBar"
          height="325"
        />
        <div className={styles.averageAirContentContainer}>
          <div className={styles.value}>{setAverageAirContentValue(this.props.avgValue)}</div>
          <div className={styles.valueLabel}>Average Air Content</div>
        </div>
      </section>
    );
  }
}

function formatNumber(series: number) {
  series = series * 100;
  return series ? [series.toFixed(2)] : [0];
}

function setAverageAirContentValue(value: number) {
  return value ? `${value.toFixed(2)}%` : 'N/A';
}

export default AirContentMetric;
