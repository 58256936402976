export const mixTypeColors = [
    '#80ff80',
    '#8080ff',
    '#008080',
    '#800080',
    '#808000',
    '#ffff80',
    '#80ffff',
    '#ff80ff',
    '#ff0080',
    '#80ff00',
    '#0080ff',
    '#00ff80',
    '#8000ff',
    '#ff8000',
    '#000080',
    '#800000',
    '#008000',
    '#404040',
    '#ff4040',
    '#40ff40',
    '#4040ff',
    '#004040',
    '#400040',
    '#404000',
    '#804040',
    '#408040',
    '#404080',
    '#ffff40',
    '#40ffff',
    '#ff40ff',
    '#ff0040',
    '#40ff00',
    '#0040ff',
    '#ff8040',
    '#40ff80',
    '#8040ff',
    '#00ff40',
    '#4000ff',
    '#ff4000',
    '#000040',
    '#400000',
    '#004000',
    '#008040',
    '#400080',
    '#804000',
    '#80ff40',
    '#4080ff',
    '#ff4080',
    '#800040',
    '#408000',
    '#004080',
    '#808040',
    '#408080',
    '#804080',
    '#40ff40',
    '#ffc0c0',
    '#c0ffc0',
    '#c0c0ff',
    '#00c0c0',
    '#c000c0',
    '#c0c000',
    '#80c0c0',
    '#c080c0',
    '#c0c080',
    '#40c0c0',
    '#c040c0',
    '#c0c040',
    '#ffffc0',
    '#c0ffff',
    '#ffc0ff',
    '#ff00c0',
    '#c0ff00',
    '#00c0ff',
    '#ff80c0',
    '#c0ff80',
    '#80c0ff',
    '#ff40c0',
    '#c0ff40',
    '#40c0ff',
    '#00ffc0',
    '#c000ff',
    '#ffc000',
    '#0000c0',
    '#c00000',
    '#00c000',
    '#0080c0',
    '#c00080',
    '#80c000',
    '#0040c0',
    '#c00040',
    '#40c000',
    '#80ffc0',
    '#c080ff',
    '#ffc080',
    '#8000c0',
    '#c08000',
    '#00c080',
    '#8080c0',
    '#c08080',
    '#80c080'
]

export default mixTypeColors;