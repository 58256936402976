import * as React from 'react';

import Interval from './interval';

import * as timeFunctions from '../controls/timeFunctions';

import ReactApexChart from 'react-apexcharts';

const styles = require('./productionTimeMetric.scss');

class ProductionTimeMetric extends React.Component<
  {
    averageDuration: Interval;
    averageWetMixTime: Interval;
    averageIdleTime: Interval;
    stdDevWetMixTimeInSec: number;
    maxDuration: Interval;
  },
  { options: any }
> {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 0,
              size: '85%',
              fill: '#0088be'
            },
            track: {
              background: '#e7e7e7',
              strokeWidth: '100%',
              margin: 0
            },
            dataLabels: {
              name: {
                offsetY: 25,
                fontSize: '1rem',
                color: '#c7c7c7',
                fontFamily: 'Roboto, sans-serif'
              },
              value: {
                formatter: function() {
                  return this.props.averageDuration ? timeFunctions.formatInterval(this.props.averageDuration) : 'N/A';
                }.bind(this),
                offsetY: -30,
                fontSize: '3rem',
                color: '#0088be'
              }
            }
          }
        },
        labels: ['Average Batch Time'],
        colors: ['#0088be']
      }
    };
  }

  render() {
    return (
      <section className={styles.productionTimeContainer}>
        <ReactApexChart
          options={this.state.options}
          series={this.setRadialPercent(this.props.averageDuration, this.props.maxDuration)}
          type="radialBar"
          height="325"
        />
        <div className={styles.mixTimeContainer}>
          <div className={styles.value}>
            {this.props.averageIdleTime ? timeFunctions.formatInterval(this.props.averageIdleTime) : 'N/A'}
          </div>
          <div className={styles.valueLabel}>Average Mixer Idle Time</div>
          <div className={styles.value}>
            {this.props.averageWetMixTime ? timeFunctions.formatInterval(this.props.averageWetMixTime) : 'N/A'}
          </div>
          <div className={styles.valueLabel}>Average Wet Mixing Time</div>
          <div className={styles.value}>
            {this.props.stdDevWetMixTimeInSec ? timeFunctions.formatSeconds(this.props.stdDevWetMixTimeInSec) : 'N/A'}
          </div>
          <div className={styles.valueLabel}>Standard Deviation of Wet Mixing Time</div>
        </div>
      </section>
    );
  }

  private setRadialPercent = (average: Interval, max: Interval) => {
    if (average && max) {
      const averageSeconds = average.hours * 3600 + average.minutes * 60 + average.seconds;
      const maxSeconds = max.hours * 3600 + max.minutes * 60 + max.seconds;
      return isNaN(averageSeconds / maxSeconds) ? [0] : [((averageSeconds / maxSeconds) * 100).toFixed(2)];
    } else {
      return [0];
    }
  };
}

export default ProductionTimeMetric;
