import * as React from 'react';

import ReactApexChart from 'react-apexcharts';

import { DailyQualitySpecs } from './dailyQualitySpecs';

import formatDate from 'date-fns/format';

const styles = require('./dailyView.scss');

interface Props {
  dailyQualitySpecs: DailyQualitySpecs;
  dates: string[];
  handlePointClick: (date: string) => void;
}

class MixQualityGraph extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  getGraphOptions = () => {
    let minTemp, maxTemp;
    this.props.dailyQualitySpecs.minTemperature.forEach(m => {
      minTemp = minTemp ? Math.min(minTemp, m) : m;
    });
    this.props.dailyQualitySpecs.maxTemperature.forEach(m => {
      maxTemp = maxTemp ? Math.max(maxTemp, m) : m;
    });
    const showXAxis: boolean = !!this.props.dates.length;
    // Area and line graphs won't show for a single data point, so when there is only one data point we have to set the marker size so the data is seen on the graph
    const markerSizes: number[] = this.props.dates.length === 1 ? [5, 5, 5, 5] : [0, 5, 0, 0];
    return {
      chart: {
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            this.props.handlePointClick(this.props.dates[config.dataPointIndex]);
          }.bind(this),
          dataPointMouseEnter: function(event) {
            event.target.style.cursor = 'pointer';
          }
        }
      },
      colors: ['#c9d1f8', '#662090', '#00f', '#f00'],
      title: {
        text: '% Within Air Target & Average Temperature',
        align: 'left',
        offsetX: 25,
        offsetY: 15,
        style: {
          fontSize: '1rem'
        }
      },
      noData: {
        text: 'No data for selected date range',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetY: -30,
        showForSingleSeries: true,
        markers: {
          onClick: undefined
        },
        onItemClick: {
          toggleDataSeries: false
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 0.7,
        type: 'solid'
      },
      stroke: {
        width: [0, 0, 3, 3],
        curve: 'straight'
      },
      markers: {
        size: markerSizes
      },
      xaxis: {
        type: 'category',
        position: 'top',
        offsetY: -40,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: showXAxis
        }
      },
      yaxis: [
        {
          labels: {
            formatter: value => {
              return value + '%';
            }
          },
          title: {
            text: '%',
            rotate: 0,
            style: {
              fontSize: '0.75rem'
            }
          },
          decimalsInFloat: 0
        },
        {
          opposite: true,
          labels: {
            formatter: value => {
              return value + '°';
            }
          },
          title: {
            text: '°F',
            rotate: 0,
            style: {
              fontSize: '0.75rem'
            }
          },
          decimalsInFloat: 0,
          forceNiceScale: true,
          min: minTemp - 10,
          max: maxTemp + 10
        },
        {
          show: false,
          opposite: true,
          forceNiceScale: true,
          min: minTemp - 10,
          max: maxTemp + 10
        },
        {
          show: false,
          opposite: true,
          forceNiceScale: true,
          min: minTemp - 10,
          max: maxTemp + 10
        }
      ],
      tooltip: {
        marker: {
          show: false
        },
        custom: function({ series, seriesIndex, dataPointIndex }) {
          const date = formatDate(this.props.dates[dataPointIndex], 'MM/dd');
          const airPercent = series[seriesIndex][dataPointIndex];
          const averageAirContent = this.props.dailyQualitySpecs.averageAirContent[dataPointIndex].toFixed(2);
          const standardAirDeviation = this.props.dailyQualitySpecs.standardAirDeviation[dataPointIndex].toFixed(2);
          const avgTemp = this.props.dailyQualitySpecs.averageTemperature[dataPointIndex].toFixed(0);
          const minTemperature = this.props.dailyQualitySpecs.minTemperature[dataPointIndex].toFixed(0);
          const maxTemperature = this.props.dailyQualitySpecs.maxTemperature[dataPointIndex].toFixed(0);
          const batches = this.props.dailyQualitySpecs.numberOfBatches[dataPointIndex];
          return this.customQualityToolTip(
            date,
            airPercent,
            averageAirContent,
            standardAirDeviation,
            avgTemp,
            minTemperature,
            maxTemperature,
            batches
          );
        }.bind(this)
      }
    };
  };

  customQualityToolTip = (
    date: string,
    apv: number,
    aac: string,
    stad: string,
    avgTemp: string,
    minTemp: string,
    maxTemp: string,
    batches: number
  ) => {
    return `<div class=${styles.dailyToolTip}>
        <div class=${styles.dateRow}>${date}</div>
        <table>
          <tbody>
            <tr class=${styles.info}>
              <td>% Within Air Target:</td>
              <td>${apv}%</td>
            </tr>
            <tr class=${styles.info}>
              <td>Average Air Content:</td>
              <td>${aac}%</td>
            </tr>
            <tr class=${styles.info}>
              <td>Standard Air Deviation:</td>
              <td>${stad}%</td>
            </tr>
            <tr class=${styles.info}>
              <td>Average Temperature:</td>
              <td>${avgTemp}°F</td>
            </tr>
            <tr class=${styles.info}>
              <td>Temp Range:</td>
              <td>${minTemp}°F - ${maxTemp}°F</td>
            <tr class=${styles.info}>
              <td># of batches:</td>
              <td>${batches}</td>
            </tr>
          </tbody>
        </table>
      </div>`;
  };

  getGraphSeries = () => {
    const airPercent = [];
    const avgTemp = [];
    const minTemp = [];
    const maxTemp = [];
    this.props.dailyQualitySpecs.airPercent.forEach((value, index) => {
      if (value) {
        value = value * 100;
        value = parseFloat(value.toFixed(2));
      }
      const date: string = formatDate(this.props.dates[index], 'MM/dd');
      airPercent.push({ x: date, y: value });
    });
    this.props.dailyQualitySpecs.averageTemperature.forEach((value, index) => {
      if (value) {
        value = parseFloat(value.toFixed(0));
      }
      const date: string = formatDate(this.props.dates[index], 'MM/dd');
      avgTemp.push({ x: date, y: value });
    });
    this.props.dailyQualitySpecs.minTemperature.forEach((value, index) => {
      if (value) {
        value = parseFloat(value.toFixed(0));
      }
      const date: string = formatDate(this.props.dates[index], 'MM/dd');
      minTemp.push({ x: date, y: value });
    });
    this.props.dailyQualitySpecs.maxTemperature.forEach((value, index) => {
      if (value) {
        value = parseFloat(value.toFixed(0));
      }
      const date: string = formatDate(this.props.dates[index], 'MM/dd');
      maxTemp.push({ x: date, y: value });
    });

    if (airPercent.length === 0 && avgTemp.length === 0 && minTemp.length === 0 && maxTemp.length === 0) {
      return [];
    } else {
      return [
        {
          name: 'Air Percentage Value',
          type: 'area',
          data: airPercent
        },
        {
          name: 'Average Temperature',
          type: 'line',
          data: avgTemp
        },
        {
          name: 'Minimum Temperature',
          type: 'line',
          data: minTemp
        },
        {
          name: 'Maximum Temperature',
          type: 'line',
          data: maxTemp
        }
      ];
    }
  };

  render() {
    const options = this.getGraphOptions();
    const series = this.getGraphSeries();
    return (
      <section className={styles.dailyGraphContainer}>
        <div className={styles.dailySectionTitle}>Concrete Mix Quality</div>
        <ReactApexChart options={options} series={series} type="line" height="350" />
      </section>
    );
  }
}

export default MixQualityGraph;
