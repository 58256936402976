import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { Redirect } from 'react-router';

const styles = require('./login.scss');
const logoImg = require('./images/gcp-logo-only.png');

export default withAuth(
  class Home extends Component<{ auth: any }, { authenticated: boolean }> {
    constructor(props) {
      super(props);
      this.state = { authenticated: null };
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.checkAuthentication();
      this.login = this.login.bind(this);
    }

    async checkAuthentication() {
      const authenticated = await this.props.auth.isAuthenticated();
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
      }
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    async login() {
      // Redirect to '/dashboard' after login
      this.props.auth.login('/dashboard');
    }

    render() {
      if (this.state.authenticated === null) return null;
      return this.state.authenticated ? (
        <Redirect to="/dashboard" />
      ) : (
        <section className={styles.container}>
          <section className={styles.welcome}>
            <img src={logoImg} alt="GCP Logo" />
            <h1>
              Welcome to the
              <br />
              GCP AIRtrac Web Portal
            </h1>
            <span>Please login to continue</span>
          </section>
          <section className={styles.loginForm}>
            <button className={styles.login} onClick={this.login}>
              Login
            </button>
          </section>
        </section>
      );
    }
  }
);
