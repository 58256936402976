import * as React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';

import Home from './home';
import NavRoutes from './navRoutes';

const config = {
  issuer: `https://${window.env.OKTA_DOMAIN}/oauth2/default`,
  redirect_uri: window.location.origin + '/implicit/callback',
  client_id: window.env.OKTA_CLIENT_ID,
  scope: `openid profile ${window.env.OKTA_SCOPE_WEB}`.split(/\s+/)
};

const Application = () => {
  return (
    <BrowserRouter basename={window.env.BASE_URL}>
      <Security {...config}>
        <Route path="/" exact={true} component={Home} />
        <Route path="/implicit/callback" component={ImplicitCallback} />
        <SecureRoute path="/dashboard" component={NavRoutes} />
        <SecureRoute path="/daily-view" component={NavRoutes} />
        <SecureRoute path="/day-view" component={NavRoutes} />
        <SecureRoute path="/company-management" component={NavRoutes} />
        <SecureRoute path="/plant-management" component={NavRoutes} />
        <SecureRoute path="/mixer-management" component={NavRoutes} />
      </Security>
    </BrowserRouter>
  );
};

export default Application;
