import * as React from 'react';
import ReactPaginate from 'react-paginate';
import { withRouter, RouteComponentProps } from 'react-router';

import axios from 'axios';

import { Company } from './management';
import AlertModal from '../controls/alertModal';
import InputModal from '../controls/inputModal';

import { toast } from 'react-toastify';

const styles = require('./companyManagement.scss');

toast.configure({
  autoClose: 5000,
  position: 'top-center'
});

interface FormState {
  companyName: string;
  errors: {
    companyName: string;
  };
}

class NewCompanyForm extends React.Component<{ accessToken: string; updateCompanyList: any }, FormState> {
  constructor(props) {
    super(props);

    this.state = {
      companyName: '',
      errors: {
        companyName: ''
      }
    };

    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCompanyNameChange = event => {
    const { value } = event.target;
    let errors = this.state.errors;

    if (value.trim().length == 0) {
      errors.companyName = 'Please enter in a company name';
    } else if (value.trim().length > 64) {
      errors.companyName = 'Company name longer than 64 characters';
    } else {
      errors.companyName = '';
    }

    this.setState({
      companyName: value,
      errors
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    let errors = this.state.errors;
    if (!this.state.companyName) {
      errors.companyName = 'Please enter in a company name';
      this.setState({
        errors
      });
    }

    const company = this.state.companyName.trim();
    if (!errors.companyName) {
      axios
        .post(`${window.env.API_URL}/api/v1/data/company`, null, {
          params: {
            name: company
          },
          headers: {
            Authorization: 'Bearer ' + this.props.accessToken
          }
        })
        .then(() => {
          toast.success(`You have successfully added ${company}`);
          this.setState({
            companyName: '',
            errors: {
              companyName: ''
            }
          });
          this.props.updateCompanyList();
        })
        .catch(error => {
          if (!error.response) {
            toast.error('Network Error');
          } else if (error.response.status === 400) {
            toast.error(error.response.data);
          } else {
            toast.error('Unexpected error');
          }
        });
    }
  };

  render() {
    const companyError = this.state.errors.companyName.length > 0 || !this.state.companyName;
    return (
      <section>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            name="companyName"
            onChange={this.handleCompanyNameChange}
            value={this.state.companyName}
            placeholder="Add New Company"
          />
          <button disabled={companyError}>Submit</button>
        </form>
        {companyError && <span className={styles.formError}>{this.state.errors.companyName}</span>}
      </section>
    );
  }
}

interface Props extends RouteComponentProps {
  accessToken: string;
}

interface State {
  isLoading: boolean;
  companies: Company[];
  size: number;
  currentPage: number;
  totalPages: number;
  showDeleteModal: boolean;
  deleteId: string;
  showEditModal: boolean;
  editId: string;
  editCompanyName: string;
  editCompanyNameErrorMessage: string;
  originalCompanyName: string;
}
class CompanyManagement extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      companies: [],
      size: 10,
      currentPage: 0,
      totalPages: 0,
      showDeleteModal: false,
      deleteId: null,
      showEditModal: false,
      editId: null,
      editCompanyName: null,
      editCompanyNameErrorMessage: null,
      originalCompanyName: null
    };

    this.getCompanies = this.getCompanies.bind(this);
  }

  componentDidMount() {
    this.getCompanies();
  }

  redirectUnauthorizedUser = () => {
    this.props.history.push({
      pathname: '/dashboard'
    });
  };

  handlePageClick = data => {
    this.getCompanies(data.selected);
  };

  editCompany = (company: Company) => {
    this.setState({
      showEditModal: true,
      editId: String(company.id),
      editCompanyName: company.name,
      editCompanyNameErrorMessage: null,
      originalCompanyName: company.name
    });
  };

  handleAcceptEdit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const name: string = this.state.editCompanyName.trim();

    this.editCompanyRequest(this.state.editId, name);
    this.setState({
      showEditModal: false,
      editId: null
    });
  };

  cancelEdit = () => {
    this.setState({
      showEditModal: false,
      editId: null,
      editCompanyName: null,
      editCompanyNameErrorMessage: null
    });
  };

  handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = event.currentTarget.value;
    let error: string = null;

    if (name.trim().length == 0) {
      error = 'Please enter in a company name';
    } else if (name.trim().length > 64) {
      error = 'Company name longer than 64 characters';
    }

    this.setState({
      editCompanyName: name,
      editCompanyNameErrorMessage: error
    });
  };

  deleteCompany = event => {
    this.setState({
      showDeleteModal: true,
      deleteId: event.target.value
    });
  };

  handleDelete = () => {
    this.deleteCompanyRequest(this.state.deleteId);
    this.setState({
      showDeleteModal: false,
      deleteId: null
    });
  };

  cancelDelete = () => {
    this.setState({
      showDeleteModal: false,
      deleteId: null
    });
  };

  render() {
    if (this.state.isLoading) return null;
    return (
      <section>
        <section className={styles.pageHeaderContainer}>
          <h3>List of Companies</h3>
          <NewCompanyForm accessToken={this.props.accessToken} updateCompanyList={this.getCompanies} />
        </section>
        <table className={styles.companyTable}>
          <thead>
            <tr>
              <th>Company ID</th>
              <th>Company Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.companies.map(c => (
              <tr key={c.id}>
                <td>{c.id}</td>
                <td>{c.name}</td>
                <td>
                  <button className={styles.buttonStyling} onClick={() => this.editCompany(c)} value={c.id}>
                    Edit
                  </button>
                  <button className={styles.buttonStyling} onClick={this.deleteCompany} value={c.id}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={this.state.totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={this.handlePageClick}
          activeClassName={styles.selected}
          disabledClassName={styles.disabled}
          containerClassName={styles.pageSelector}
          forcePage={this.state.currentPage}
        />
        <AlertModal
          show={this.state.showDeleteModal}
          message={'Are you sure you want to delete this Company?'}
          handleYes={this.handleDelete}
          handleNo={this.cancelDelete}
        />
        <InputModal
          inputValue={this.state.editCompanyName}
          originalValue={this.state.originalCompanyName}
          handleOk={this.handleAcceptEdit}
          handleCancel={this.cancelEdit}
          handleInputChange={this.handleCompanyNameChange}
          show={this.state.showEditModal}
          errorMessage={this.state.editCompanyNameErrorMessage}
          title={'Edit Company Name'}
          placeHolder={'Enter a Company Name'}
        />
      </section>
    );
  }

  async getCompanies(requestPage = 0) {
    axios
      .get(`${window.env.API_URL}/api/v1/data/companies`, {
        params: {
          page: requestPage,
          size: this.state.size
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.setState({
          isLoading: false,
          companies: response.data.content,
          totalPages: response.data.totalPages,
          currentPage: requestPage
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async deleteCompanyRequest(companyId: string) {
    axios
      .delete(`${window.env.API_URL}/api/v1/data/company/${companyId}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(() => {
        toast.success('Successfully deleted company');
        this.getCompanies(0);
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data);
        } else {
          toast.error(error.message);
        }
      });
  }

  async editCompanyRequest(companyId: string, companyName: string) {
    axios
      .put(
        `${window.env.API_URL}/api/v1/data/company`,
        {
          id: companyId,
          name: companyName
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.accessToken
          }
        }
      )
      .then(() => {
        toast.success('Successfully edited company name');
        this.getCompanies(0);
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data);
        } else {
          toast.error(error.message);
        }
      });
  }
}

export default withRouter(CompanyManagement);
