import React from 'react';

import { OptionDropdownItem } from './dropdownItem';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import _ from 'lodash';

const styles = require('./dropdown.scss');

interface DropdownProps {
  items: OptionDropdownItem[];
  selectedId?: string;
  placeholder?: string;
  handleSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  disableDropdown: boolean;
}

const defaultProps: Partial<DropdownProps> = {
  selectedId: '',
  placeholder: ''
};

function displayToolTip(items: OptionDropdownItem[], selectedId: string) {
  if (items.length > 0 && !!selectedId) {
    const selectedItem = items.find(i => {
      return i.id === selectedId;
    });
    return selectedItem.text;
  } else {
    return '';
  }
}

const OptGroupDropdown: React.FunctionComponent<DropdownProps> = props => (
  <div className={styles.dropdown}>
    <FormControl className={styles.formControl} disabled={props.disableDropdown}>
      <InputLabel>{props.placeholder}</InputLabel>
      <Select
        title={displayToolTip(props.items, props.selectedId)}
        value={props.selectedId}
        onChange={props.handleSelect}
        className={styles.selectEmpty}
      >
        {props.items.map(p => (
          <MenuItem key={p.id} value={p.id} disabled={p.isGroup} className={p.isGroup ? '' : styles.groupOption}>
            {p.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

OptGroupDropdown.defaultProps = defaultProps;

export default OptGroupDropdown;
