import React from 'react';

const styles = require('./alertModal.scss');

interface ModalInfo {
  message: string;
  handleYes: (event: any) => void;
  handleNo: (event: any) => void;
  show: boolean;
}

const AlertModal: React.FunctionComponent<ModalInfo> = props => {
  if (props.show) {
    return (
      <section className={styles.modal}>
        <div className={styles.modalBody}>
          <p>{props.message}</p>
          <div className={styles.buttonContainer}>
            <button onClick={props.handleYes}>Yes</button>
            <button onClick={props.handleNo}>No</button>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default AlertModal;
