import React from 'react';

const styles = require('./alertModal.scss');

interface Props {
  inputValue: string;
  originalValue: string;
  handleOk: (event: React.FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  show: boolean;
  errorMessage: string;
  title: string;
  placeHolder: string;
}

const InputModal: React.FunctionComponent<Props> = props => {
  if (!props.show) {
    return null;
  }
  return (
    <section className={styles.modal}>
      <div className={styles.modalTitle}>{props.title}</div>
      <div className={styles.modalBody}>
        <form className={styles.formBody} onSubmit={props.handleOk}>
          <input
            autoFocus
            type="text"
            name="name"
            onChange={props.handleInputChange}
            value={props.inputValue}
            placeholder={props.placeHolder}
            className={styles.inputField}
          />
          {!!props.errorMessage && <span className={styles.formError}>{props.errorMessage}</span>}
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              disabled={!!props.errorMessage || props.originalValue.trim() === props.inputValue.trim()}
            >
              Ok
            </button>
            <button type="button" onClick={props.handleCancel} className={styles.formButton}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default InputModal;
