import React from 'react';
import { toast } from 'react-toastify';

import { Plant, Mixer } from '../management/management';

import { MultiSelectItem } from './dropdownItem';
import formatDate from 'date-fns/format';

import axios from 'axios';
import qs from 'qs';
import { saveAs } from 'file-saver';

toast.configure({
  autoClose: 5000,
  position: 'top-center'
});

interface Filters {
  startDate: Date;
  endDate: Date;
  selectedPlants: Plant[];
  selectedMixers: Mixer[];
  selectedMixTypes: MultiSelectItem[];
  selectedCompany: string;
}

interface Props {
  accessToken: string;
  isGcpAdmin: boolean;
  filters: Filters;
}

interface State {
  filters: Filters;
  isExporting: boolean;
}

class ExportButton extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      filters: this.props.filters,
      isExporting: false
    };

    this.exportData = this.exportData.bind(this);
  }

  render() {
    return (
      <button type="button" onClick={this.exportData} disabled={this.state.isExporting}>
        {this.state.isExporting ? 'Exporting...' : 'Export'}
      </button>
    );
  }

  async exportData() {
    this.setState({ isExporting: true });
    const startDate: string = formatDate(this.state.filters.startDate, 'yyyy-MM-dd');
    const endDate: string = formatDate(this.state.filters.endDate, 'yyyy-MM-dd');

    let params = {
      startDate: startDate,
      endDate: endDate
    };

    if (this.props.isGcpAdmin) {
      params['company'] = this.state.filters.selectedCompany;
    }

    if (this.state.filters.selectedPlants.length > 0) {
      params['plants'] = this.state.filters.selectedPlants.map(plant => plant.id);
    }

    if (this.state.filters.selectedMixers.length > 0) {
      params['mixers'] = this.state.filters.selectedMixers.map(mixer => mixer.id);
    }

    if (this.state.filters.selectedMixTypes.length > 0) {
      params['mixIds'] = this.state.filters.selectedMixTypes.map(mix => mix.name);
    }

    axios
      .get(`${window.env.API_URL}/api/v1/data/export`, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const contentDisposition: string = response.headers['content-disposition'];

        // Export filenames must contain at least one character, and must have the txt extension
        const filenameRegex = /filename="(.+?\.txt)"/;
        const matches = contentDisposition.match(filenameRegex);
        const filename =
          !!matches && matches.length === 2
            ? contentDisposition.match(filenameRegex)[1]
            : `DailyBatchSummary_${startDate}_${endDate}.txt`;
        const blob = new Blob([response.data], { type: 'text/plain' });
        saveAs(blob, filename);
        this.setState({ isExporting: false });
      })
      .catch(error => {
        if (!toast.isActive(error.message)) {
          toast.error(error.message, { toastId: error.message });
        }
        this.setState({ isExporting: false });
      });
  }
}

export default ExportButton;
