import React from 'react';
import { toast } from 'react-toastify';

import { Plant, Mixer } from '../management/management';
import { MultiSelectItem } from '../controls/dropdownItem';
import { NavLink } from 'react-router-dom';

import Loading from '../controls/loading';

import ReactApexChart from 'react-apexcharts';

import startOfWeek from 'date-fns/startOfWeek';
import formatDate from 'date-fns/format';

import axios from 'axios';
import qs from 'qs';

import Interval from '../dashboard/interval';

import * as timeFunctions from '../controls/timeFunctions';

import mixTypeColors from '../controls/mixTypeColors';

import ExportButton from '../controls/exportButton';

const styles = require('./dayView.scss');

toast.configure({
  autoClose: 5000,
  position: 'top-center'
});

interface Filters {
  selectedDate: Date;
  startDate: Date;
  endDate: Date;
  selectedPlants: Plant[];
  selectedMixers: Mixer[];
  selectedMixTypes: MultiSelectItem[];
  selectedCompany: string;
}

interface Batch {
  mixId: string;
  airContent: number;
  hcf: number;
  yieldVariance: number;
  finalTemperature: number;
  dischargeTime: string;
  batchDuration: Interval;
  wetMixTime: Interval;
  mixerIdleTime: Interval;
  mixCode: number;
}

interface Mixers {
  [name: string]: Batch[];
}

interface Plants {
  [name: string]: Mixers[];
}

interface State {
  filters: Filters;
  plants: Plants[];
  isLoading: boolean;
  isExporting: boolean;
}

interface Props {
  accessToken: string;
  isGcpAdmin: boolean;
}

class DayView extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const selectedDate = (props.location.state && props.location.state.selectedDate) || new Date();
    const startDate = (props.location.state && props.location.state.startDate) || startOfWeek(new Date());
    const endDate = (props.location.state && props.location.state.endDate) || new Date();
    const selectedPlants = (props.location.state && props.location.state.selectedPlants) || [];
    const selectedMixers = (props.location.state && props.location.state.selectedMixers) || [];
    const selectedMixTypes = (props.location.state && props.location.state.selectedMixTypes) || [];
    const selectedCompany = (props.location.state && props.location.state.selectedCompany) || '';

    this.state = {
      filters: {
        selectedDate: selectedDate,
        startDate: startDate,
        endDate: endDate,
        selectedPlants: selectedPlants,
        selectedMixers: selectedMixers,
        selectedMixTypes: selectedMixTypes,
        selectedCompany: selectedCompany
      },
      plants: [],
      isLoading: true,
      isExporting: false
    };
  }

  componentDidMount() {
    this.loadDayView();
  }

  async loadDayView() {
    if (this.props.isGcpAdmin && !this.state.filters.selectedCompany) {
      await this.getCompany();
      if (this.state.filters.selectedCompany) {
        this.getDayData();
      }
    } else {
      this.getDayData();
    }
  }

  render() {
    const isEmpty = Object.keys(this.state.plants).length === 0;
    const date = formatDate(this.state.filters.selectedDate, 'MM/dd/yyyy');
    return (
      <section className={styles.dayViewContainer}>
        <section className={styles.breadcrumbs}>
          <NavLink
            to={{
              pathname: '/dashboard',
              state: {
                startDate: this.state.filters.startDate,
                endDate: this.state.filters.endDate,
                selectedPlants: this.state.filters.selectedPlants,
                selectedMixers: this.state.filters.selectedMixers,
                selectedMixTypes: this.state.filters.selectedMixTypes,
                selectedCompany: this.state.filters.selectedCompany
              }
            }}
            className={styles.dailyView}
          >
            <span>Executive Summary</span>
          </NavLink>
          <span> > </span>
          <NavLink
            to={{
              pathname: '/daily-view',
              state: {
                startDate: this.state.filters.startDate,
                endDate: this.state.filters.endDate,
                selectedPlants: this.state.filters.selectedPlants,
                selectedMixers: this.state.filters.selectedMixers,
                selectedMixTypes: this.state.filters.selectedMixTypes,
                selectedCompany: this.state.filters.selectedCompany
              }
            }}
            className={styles.dailyView}
          >
            <span>Quality & Production</span>
          </NavLink>
          <span> > </span>
          <span>Compound Data</span>
        </section>
        <section className={styles.sectionHeader}>
          <h2>Compound Data by Batch</h2>
          <h3>Final Air Content & Final Temperature</h3>
          <h5>Date: {date}</h5>
          <ExportButton
            accessToken={this.props.accessToken}
            isGcpAdmin={this.props.isGcpAdmin}
            filters={{
              ...this.state.filters,
              startDate: this.state.filters.selectedDate,
              endDate: this.state.filters.selectedDate
            }}
          />
        </section>
        <section className={styles.graphsContainer}>
          {this.state.isLoading && (
            <div className={isEmpty ? styles.emptyGraphs : ''}>
              <Loading />
            </div>
          )}
          <p className={isEmpty ? styles.noData : styles.hasData}>No data for selected date</p>
          {Object.keys(this.state.plants).map((plantName, i) => (
            <section key={i} className={styles.dayGraphContainer}>
              <h3>{plantName}</h3>
              {Object.keys(this.state.plants[plantName]).map((mixer, i) => (
                <section key={i} className={styles.batchGraphContainer}>
                  <ReactApexChart
                    options={this.getOptions(mixer, plantName)}
                    series={this.getSeries(this.state.plants[plantName][mixer])}
                    type="line"
                    height="350"
                  />
                </section>
              ))}
            </section>
          ))}
        </section>
      </section>
    );
  }

  getOptions = (mixerName: string, plantName: string) => {
    let maxTime;
    const mixColors: string[] = [];
    this.state.plants[plantName][mixerName].forEach(b => {
      const batchDuration = timeFunctions.intervalToSeconds(b.batchDuration);
      maxTime = maxTime ? Math.max(maxTime, batchDuration) : batchDuration;
      mixColors.push(mixTypeColors[b.mixCode]);
    });

    return {
      chart: {
        stacked: true,
        stackType: 'normal',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        selection: {
          enabled: false
        }
      },
      states: {
        active: {
          filter: {
            type: 'none'
          }
        }
      },
      colors: ['#662090', '#516fff', '#5fb2e8', '#94ccf0'],
      title: {
        text: mixerName,
        align: 'left',
        offsetX: 25,
        offsetY: 15,
        style: {
          fontSize: '1rem'
        }
      },
      noData: {
        text: 'No data for selected date range',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetY: -30,
        showForSingleSeries: true,
        markers: {
          onClick: undefined
        },
        onItemClick: {
          toggleDataSeries: false
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: [1, 1, 1, 1],
        type: 'solid'
      },
      stroke: {
        width: [0, 0, 1, 1]
      },
      markers: {
        size: [5, 8, 0, 0]
      },
      xaxis: {
        type: 'category',
        labels: {
          show: true,
          rotate: -45,
          style: {
            colors: mixColors,
            fontSize: '1rem',
            cssClass: styles.dayBatchXAxis
          },
          formatter: function(value, timestamp, index) {
            const batchNum = index + 1;
            return batchNum;
          }
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: [
        {
          seriesName: 'Final Temperature',
          labels: {
            formatter: value => {
              return value + '°';
            },
            offsetX: 10
          },
          title: {
            text: '°F',
            rotate: 0,
            style: {
              fontSize: '0.75rem'
            },
            offsetX: 5
          },
          decimalsInFloat: 0
        },
        {
          seriesName: 'Final Air Content',
          labels: {
            formatter: value => {
              return value + '%';
            },
            offsetX: 25
          },
          title: {
            text: '%',
            rotate: 0,
            style: {
              fontSize: '0.75rem'
            },
            offsetX: 5
          },
          decimalsInFloat: 0
        },
        {
          seriesName: 'Wet Mix Time',
          opposite: true,
          forceNiceScale: true,
          labels: {
            formatter: value => {
              return timeFunctions.formatSeconds(value);
            }
          },
          title: {
            text: 'Time',
            rotate: 90,
            style: {
              fontSize: '0.75rem'
            }
          },
          min: 0,
          max: maxTime
        },
        {
          seriesName: 'Batch Time',
          opposite: true,
          forceNiceScale: true,
          show: false,
          min: 0,
          max: maxTime
        }
      ],
      tooltip: {
        marker: {
          show: false
        },
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const mixerName = w.config.title.text;
          const batchInfo: Batch = this.state.plants[plantName][mixerName][dataPointIndex];

          const batchNum = dataPointIndex + 1;
          const mixType = batchInfo.mixId;
          const hcf = batchInfo.hcf;
          const airContent = batchInfo.airContent.toFixed(2);
          const yieldVariance = batchInfo.yieldVariance.toFixed(2);
          const finalTemp = batchInfo.finalTemperature.toFixed(2);
          const dischargeTime = batchInfo.dischargeTime;
          const batchDuration = timeFunctions.formatInterval(batchInfo.batchDuration);
          const wetMixTime = timeFunctions.formatInterval(batchInfo.wetMixTime);
          const mixerIdleTime = timeFunctions.formatInterval(batchInfo.mixerIdleTime);
          return this.customQualityToolTip(
            batchNum,
            mixType,
            hcf,
            airContent,
            yieldVariance,
            finalTemp,
            dischargeTime,
            batchDuration,
            wetMixTime,
            mixerIdleTime
          );
        }.bind(this)
      }
    };
  };

  customQualityToolTip = (
    batchNum: number,
    mixType: string,
    hcf: number,
    airContent: string,
    yieldVariance: string,
    finalTemp: string,
    dischargeTime: string,
    batchDuration: string,
    wetMixTime: string,
    mixerIdleTime: string
  ) => {
    return `<div class=${styles.dayToolTip}>
        <div class=${styles.dateRow}>Batch ${batchNum}</div>
        <table>
          <tbody>
            <tr class=${styles.info}>
              <td>Type:</td>
              <td>${mixType}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Discharge time:</td>
              <td>${dischargeTime}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Final Air Volume %:</td>
              <td>${airContent}%</td>
            </tr>
            <tr class=${styles.info}>
              <td>Temp:</td>
              <td>${finalTemp}°F</td>
            </tr>
            <tr class=${styles.info}>
              <td>Batch Time:</td>
              <td>${batchDuration}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Wet Mix Time:</td>
              <td>${wetMixTime}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Mixer Idle Time (time between </td>
              <td></td>
            </tr>
            <tr class=${styles.info}>
              <td>this and previous batch):</td>
              <td>${mixerIdleTime}</td>
            </tr>
            <tr class=${styles.info}>
              <td>HCF:</td>
              <td>${hcf}</td>
            </tr>
            <tr class=${styles.info}>
              <td>Yield Variance:</td>
              <td>${yieldVariance}%</td>
            </tr>
          </tbody>
        </table>
      </div>`;
  };

  getSeries = (batches: any[]) => {
    const airContent = [];
    const finalTemp = [];
    const batchTime = [];
    const wetMixTime = [];
    batches.forEach(b => {
      airContent.push({ x: b.mixId, y: b.airContent });
      const totalTime =
        timeFunctions.intervalToSeconds(b.batchDuration) - timeFunctions.intervalToSeconds(b.wetMixTime);
      finalTemp.push({ x: b.mixId, y: b.finalTemperature });
      wetMixTime.push({ x: b.mixId, y: timeFunctions.intervalToSeconds(b.wetMixTime) });
      batchTime.push({ x: b.mixId, y: totalTime });
    });

    /*
      Apex charts stacks all of the same chart types, including line graphs so to get the markers at the correct position we used scatter as a type
    */
    return [
      {
        name: 'Final Temperature',
        type: 'scatter',
        data: finalTemp
      },
      {
        name: 'Final Air Content',
        type: 'line',
        data: airContent
      },
      {
        name: 'Wet Mix Time',
        type: 'column',
        data: wetMixTime
      },
      {
        name: 'Batch Time',
        type: 'column',
        data: batchTime
      }
    ];
  };

  async getDayData() {
    const date = formatDate(this.state.filters.selectedDate, 'yyyy-MM-dd');
    let params = {
      date: date
    };

    if (this.props.isGcpAdmin) {
      params['company'] = this.state.filters.selectedCompany;
    }

    if (this.state.filters.selectedPlants.length > 0) {
      params['plants'] = this.state.filters.selectedPlants.map(plant => plant.id);
    }

    if (this.state.filters.selectedMixers.length > 0) {
      params['mixers'] = this.state.filters.selectedMixers.map(mixer => mixer.id);
    }

    if (this.state.filters.selectedMixTypes.length > 0) {
      params['mixIds'] = this.state.filters.selectedMixTypes.map(mix => mix.name);
    }

    axios
      .get(`${window.env.API_URL}/api/v1/data/dayView`, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.setState({
          plants: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          if (!toast.isActive(error.response.data)) {
            toast.error(error.response.data, { toastId: error.response.data });
          }
        } else {
          if (!toast.isActive(error.message)) {
            toast.error(error.message, { toastId: error.message });
          }
        }
        this.setState({ isLoading: false });
      });
  }

  async getCompany() {
    await axios
      .get(`${window.env.API_URL}/api/v1/data/allCompanies`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        if (data.length > 0) {
          this.setState({
            filters: {
              ...this.state.filters,
              selectedCompany: String(data[0].id)
            }
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          if (!toast.isActive(error.response.data)) {
            toast.error(error.response.data, { toastId: error.response.data });
          }
        } else {
          if (!toast.isActive(error.message)) {
            toast.error(error.message, { toastId: error.message });
          }
        }
        this.setState({ isLoading: false });
      });
  }
}

export default DayView;
