import * as React from 'react';

const styles = require('./batchNumberMetric.scss');

class BatchNumberMetric extends React.Component<{ totalBatches: number; batchesPerHour: number }> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className={styles.batchNumberContainer}>
        <div className={styles.value}>{this.formatNumber(this.props.batchesPerHour)}</div>
        <div className={styles.valueLabel}>Number of Batches/HR</div>
        <div className={styles.value}>{this.props.totalBatches}</div>
        <div className={styles.valueLabel}>Total Number of Batches</div>
      </section>
    );
  }

  formatNumber = (batches: number) => {
    return batches ? batches.toFixed(2) : 0;
  };
}

export default BatchNumberMetric;
