import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import { SecureRoute } from '@okta/okta-react';
import Dashboard from './dashboard/dashboard';
import CompanyManagement from './management/companyManagement';
import PlantManagement from './management/plantManagement';
import DailyView from './dailyView/dailyView';
import MixerManagement from './management/mixerManagement';
import DayView from './dayView/dayView';

const styles = require('./app.scss');
const logoImg = require('./images/gcp-logo-only.png');
const dashImg = require('./images/dashboard-icon.png');
const companyImg = require('./images/company-icon.png');
const plantImg = require('./images/plant-icon.png');
const mixerImg = require('./images/mixer-icon.png');

export default withAuth(
  class NavRoutes extends Component<
    { auth: any },
    { authenticated: boolean; user: any; accessToken: string; isGcpAdmin: boolean; isCompanyAdmin: boolean }
  > {
    constructor(props) {
      super(props);
      this.state = { authenticated: null, user: null, accessToken: null, isGcpAdmin: false, isCompanyAdmin: false };
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    componentDidMount() {
      this.checkAuthentication();
    }

    async checkAuthentication() {
      const authenticated = await this.props.auth.isAuthenticated();
      if (authenticated !== this.state.authenticated) {
        const user = await this.props.auth.getUser();
        const accessToken = await this.props.auth.getAccessToken();

        /**
         * When clearing cache and performing a hard reload,
         * there is an unknown issue with Apex Charts rendering 4 times and running in an error:
         * apexcharts.common.js:6 Error: <g> attribute transform: Expected number, "translate(NaN, 0)".
         * Setting the state all at once resolves this issue.
         *
         * The cause seems like it may be related to the charts not correctly being destroyed when a component is unmounted.
         *  */
        this.setState({ authenticated, user, accessToken });
      }
    }

    async login() {
      // Redirect to '/' after login
      this.props.auth.login('/');
    }

    async logout() {
      // Redirect to '/' after logout
      this.props.auth.logout('/');
    }

    render() {
      const name = this.state.user ? this.state.user.name : '';
      const welcome = 'Welcome' + (name != '' ? `, ${name}` : '');
      const isGcpAdmin = this.state.user
        ? this.state.user.AdminGroups.indexOf(window.env.OKTA_GCP_ADMIN_GROUP) > -1
        : false;
      const isCompanyAdmin = this.state.user
        ? this.state.user.AdminGroups.indexOf(window.env.OKTA_COMPANY_ADMIN_GROUP) > -1
        : false;
      return (
        <section className={styles.appContainer}>
          <header>
            <div className={styles.title}>
              <img src={logoImg} alt="GCP Logo"></img>
              <span>GCP AIRTRAC WEB PORTAL</span>
            </div>
            <div className={styles.logout}>
              {welcome}
              <button type="button" onClick={this.logout} className={styles.logout}>
                Log Out
              </button>
            </div>
          </header>
          <nav>
            <NavLink to="/dashboard" activeClassName={styles.active}>
              <div className={styles.navLinkWrapper}>
                <img src={dashImg} alt="Dashboard" />
                Dashboard
              </div>
            </NavLink>
            {isGcpAdmin && (
              <NavLink to="/company-management" activeClassName={styles.active}>
                <div className={styles.navLinkWrapper}>
                  <img src={companyImg} alt="Companies" />
                  Manage Companies
                </div>
              </NavLink>
            )}
            {(isGcpAdmin || isCompanyAdmin) && (
              <NavLink to="/plant-management" activeClassName={styles.active}>
                <div className={styles.navLinkWrapper}>
                  <img src={plantImg} alt="Plants" />
                  Manage Plants
                </div>
              </NavLink>
            )}
            {isGcpAdmin && (
              <NavLink to="/mixer-management" activeClassName={styles.active}>
                <div className={styles.navLinkWrapper}>
                  <img src={mixerImg} alt="Mixers" />
                  Manage Mixers
                </div>
              </NavLink>
            )}
          </nav>
          {this.state.accessToken && (
            <main>
              <SecureRoute
                path="/dashboard"
                component={props => (
                  <Dashboard accessToken={this.state.accessToken} isGcpAdmin={isGcpAdmin} {...props} />
                )}
              />
              <SecureRoute
                path="/daily-view"
                component={props => (
                  <DailyView accessToken={this.state.accessToken} isGcpAdmin={isGcpAdmin} {...props} />
                )}
              />
              <SecureRoute
                path="/day-view"
                component={props => <DayView accessToken={this.state.accessToken} isGcpAdmin={isGcpAdmin} {...props} />}
              />
              <SecureRoute
                path="/company-management"
                component={() => <CompanyManagement accessToken={this.state.accessToken} />}
              />
              <SecureRoute
                path="/plant-management"
                component={() => (
                  <PlantManagement accessToken={this.state.accessToken} isCompanyAdmin={isCompanyAdmin} />
                )}
              />
              <SecureRoute
                path="/mixer-management"
                component={() => <MixerManagement accessToken={this.state.accessToken} />}
              />
            </main>
          )}
        </section>
      );
    }
  }
);
