import React from 'react';

import { DropdownItem } from './dropdownItem';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const styles = require('./dropdown.scss');

interface DropdownProps {
  items: DropdownItem[];
  selectedId: string;
  placeholder?: string;
  handleSelect: (event: React.FormEvent<HTMLSelectElement>) => void;
  label?: string;
  disableDropdown?: boolean;
  isFilter?: boolean;
}

const defaultProps: Partial<DropdownProps> = {
  selectedId: '',
  placeholder: '',
  label: '',
  disableDropdown: false,
  isFilter: false
};

function displayToolTip(items: DropdownItem[], selectedId: string) {
  if (items.length > 0 && !!selectedId) {
    const selectedItem = items.find(i => {
      return i.id === selectedId;
    });
    return selectedItem.text;
  } else {
    return '';
  }
}

const Dropdown: React.FunctionComponent<DropdownProps> = props => (
  <div className={styles.dropdown}>
    <FormControl
      className={props.isFilter ? styles.formControlFilterDropdown : styles.formControl}
      disabled={props.disableDropdown}
    >
      <InputLabel className={styles.placeholder}>{props.placeholder}</InputLabel>
      <Select
        title={displayToolTip(props.items, props.selectedId)}
        value={props.selectedId}
        onChange={props.handleSelect}
        displayEmpty
        className={styles.selectEmpty}
      >
        {props.items.map(p => (
          <MenuItem key={p.id} value={p.id}>
            {p.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

Dropdown.defaultProps = defaultProps;

export default Dropdown;
