import * as React from 'react';

const styles = require('./standardAirDeviation.scss');

class StandardAirDeviationMetric extends React.Component<{ value: number }> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className={styles.airDeviationContainer}>
        <div className={styles.value}>{setAirDeviationValue(this.props.value)}</div>
        <div className={styles.valueLabel}>Standard Air Deviation</div>
      </section>
    );
  }
}

function setAirDeviationValue(airValue: number) {
  return airValue ? `${airValue.toFixed(2)}%` : 'N/A';
}

export default StandardAirDeviationMetric;
