import React from 'react';

const styles = require('./loading.scss');

const Loading = () => (
  <section className={styles.loadingContainer}>
    <div className={styles.loadingMessage}>Loading</div>
    <div className={styles.loadingBar}>
      <div className={styles.loadingIndicator}></div>
      <div className={styles.loadingIndicator}></div>
      <div className={styles.loadingIndicator}></div>
      <div className={styles.loadingIndicator}></div>
    </div>
  </section>
);

export default Loading;
