import Interval from '../dashboard/interval';

import set from 'date-fns/set';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import setSeconds from 'date-fns/setSeconds';

// Ignores months and years, since we don't use those fields at the moment and there's no exact conversion rate
export const intervalToSeconds = (interval: Interval): number => {
  return interval.days * 86400 + interval.hours * 3600 + interval.minutes * 60 + interval.seconds;
};

export const formatInterval = (interval: Interval): string => {
  let timeFormat = '';
  if (interval.hours > 0) {
    timeFormat = 'H:mm:ss';
  } else if (interval.minutes > 0) {
    timeFormat = 'm:ss';
  } else {
    timeFormat = ':ss';
  }

  const time = set(new Date(), {
    hours: Math.floor(interval.hours),
    minutes: Math.floor(interval.minutes),
    seconds: Math.round(interval.seconds)
  });

  return format(time, timeFormat);
};

export const formatSeconds = (seconds: number): string => {
  let timeFormat = '';
  if (seconds > 3600) {
    timeFormat = 'H:mm:ss';
  } else if (seconds > 60) {
    timeFormat = 'm:ss';
  } else {
    timeFormat = ':ss';
  }
  const time = setSeconds(startOfDay(new Date()), seconds);

  return format(time, timeFormat);
};
