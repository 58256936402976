import * as React from 'react';

const styles = require('./temperatureMetric.scss');

class TemperatureMetric extends React.Component<{ value: number; lowTemp: number; highTemp: number }> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className={styles.temperatureContainer}>
        <div className={styles.value}>{setTemperatureString(this.props.value)}</div>
        <div className={styles.valueLabel}>Average Temperature</div>
        <div className={styles.tempRange}>
          TEMP RANGE = {setTemperatureString(this.props.lowTemp)} - {setTemperatureString(this.props.highTemp)}
        </div>
      </section>
    );
  }
}

function setTemperatureString(airValue: number) {
  return airValue ? airValue.toFixed(2) + String.fromCharCode(176) + 'F' : 'N/A';
}

export default TemperatureMetric;
