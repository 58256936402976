import * as React from 'react';
import ReactPaginate from 'react-paginate';
import { withRouter, RouteComponentProps } from 'react-router';

import { Company, Plant, Mixer } from './management';

import Dropdown from '../controls/dropdown';
import OptGroupDropdown from '../controls/optGroupDropdown';
import { DropdownItem, OptionDropdownItem } from '../controls/dropdownItem';
import AlertModal from '../controls/alertModal';

import axios from 'axios';

import { toast } from 'react-toastify';

import _ from 'lodash';

const styles = require('./mixerManagement.scss');

toast.configure({
  autoClose: 5000,
  position: 'top-center'
});

interface Props extends RouteComponentProps {
  accessToken: string;
}

interface MixerManagementState {
  isLoading: boolean;
  companies: Company[];
  selectedCompany: Company;
  plants: Plant[];
  selectedPlant: Plant;
  plantMixers: Mixer[];
  mixerList: Mixer[];
  newMixerName: string;
  errors: {
    mixerName: string;
  };
  selectedMixer: Mixer;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  showModal: boolean;
  unassignId: string;
}

class MixerManagement extends React.Component<Props, MixerManagementState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      companies: [],
      selectedCompany: null,
      plants: [],
      selectedPlant: null,
      plantMixers: [],
      mixerList: [],
      selectedMixer: null,
      newMixerName: '',
      errors: {
        mixerName: ''
      },
      pageSize: 10,
      currentPage: 0,
      totalPages: 0,
      showModal: false,
      unassignId: null
    };

    this.handleCompanySelection = this.handleCompanySelection.bind(this);
    this.handlePlantSelection = this.handlePlantSelection.bind(this);
    this.handleMixerSelect = this.handleMixerSelect.bind(this);
    this.handleMixerNameChange = this.handleMixerNameChange.bind(this);
    this.assignMixer = this.assignMixer.bind(this);
  }

  componentDidMount() {
    this.getAllCompanies();
  }

  redirectUnauthorizedUser = () => {
    this.props.history.push({
      pathname: '/dashboard'
    });
  };

  handleCompanySelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const company: Company = {
      id: Number(event.target.value),
      name: event.currentTarget.innerText.trim()
    };
    this.setState({
      selectedCompany: company,
      plantMixers: [],
      currentPage: 0,
      totalPages: 0,
      selectedPlant: null,
      selectedMixer: null,
      newMixerName: '',
      errors: {
        mixerName: ''
      }
    });
    this.getAllPlantsForComapny(company);
    this.getUnassignedAndPlantMixers(company);
  };

  handlePlantSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const plant: Plant = {
      id: event.target.value,
      name: event.currentTarget.innerText.trim(),
      company: this.state.selectedCompany.name
    };
    this.setState({
      selectedPlant: plant,
      selectedMixer: null,
      newMixerName: '',
      errors: {
        mixerName: ''
      }
    });
    this.getMixersForPlant(0, plant);
  };

  handleMixerSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    let mixer = this.state.mixerList.find(mixer => {
      return mixer.id === String(value);
    });
    this.setState({
      selectedMixer: mixer,
      newMixerName: mixer.name,
      errors: {
        mixerName: ''
      }
    });
  };

  handleMixerNameChange = event => {
    const { value } = event.target;
    let errors = this.state.errors;

    if (value.trim().length == 0) {
      errors.mixerName = 'Please enter in a mixer name';
    } else if (value.trim().length > 64) {
      errors.mixerName = 'Mixer name cannot be longer than 64 characters';
    } else {
      errors.mixerName = '';
    }
    this.setState({ newMixerName: value, errors });
  };

  assignMixer = () => {
    this.assignMixerToPlant(this.state.selectedPlant.id, this.state.selectedMixer.id, this.state.newMixerName.trim());
  };

  handlePageClick = page => {
    this.getMixersForPlant(page.selected);
  };

  unassignMixer = event => {
    this.setState({
      showModal: true,
      unassignId: event.target.value
    });
  };

  handleUnassign = () => {
    this.unassignMixerFromPlant(this.state.selectedPlant.id, this.state.unassignId);
    this.setState({
      showModal: false,
      unassignId: null,
      newMixerName: ''
    });
  };

  cancelUnassign = () => {
    this.setState({
      showModal: false,
      unassignId: null
    });
  };

  render() {
    if (this.state.isLoading) return null;
    const companyId: string = this.state.selectedCompany ? String(this.state.selectedCompany.id) : '';
    const comps: DropdownItem[] = [];
    this.state.companies.forEach(c => {
      comps.push({ id: String(c.id), text: c.name });
    });
    const compsPlaceHolder = comps.length === 0 ? 'There are no Companies to Select' : 'Select a Company';

    const plantId: string = this.state.selectedPlant ? this.state.selectedPlant.id : '';
    const plants: DropdownItem[] = [];
    this.state.plants.forEach(p => {
      plants.push({ id: p.id, text: p.name });
    });

    let plantsPlaceHolder: string = '';
    if (!this.state.selectedCompany) {
      plantsPlaceHolder = 'Select a Company Above';
    } else if (plants.length === 0) {
      plantsPlaceHolder = 'No Plants for the Selected Company';
    } else {
      plantsPlaceHolder = 'Select a Plant';
    }

    const mixerId: string = this.state.selectedMixer ? this.state.selectedMixer.id.toString() : '';

    const mixerGroup: OptionDropdownItem[] = [];
    _.chain(this.state.mixerList)
      .groupBy('plant')
      .map((value, key) => ({ group: key, mixer: value }))
      .value()
      .filter(groups => {
        return this.state.selectedPlant ? groups.group !== this.state.selectedPlant.name : groups;
      })
      .forEach((grouping, index) => {
        mixerGroup.push({
          id: 'g' + index,
          text: grouping.group,
          isGroup: true
        });
        grouping.mixer.forEach(m => {
          mixerGroup.push({
            id: String(m.id),
            text: m.name,
            isGroup: false
          });
        });
      });

    let mixerPlaceHolder: string = '';
    if (!this.state.selectedPlant) {
      mixerPlaceHolder = 'Select a Plant Above';
    } else if (mixerGroup.length === 0) {
      mixerPlaceHolder = 'No Available Mixers for the Selected Company';
    } else {
      mixerPlaceHolder = 'Select a Mixer';
    }

    const mixerError = this.state.errors.mixerName.length > 0;

    const disableAssignMixer =
      !this.state.selectedCompany ||
      !this.state.selectedPlant ||
      !this.state.selectedMixer ||
      !this.state.selectedMixer.id ||
      !this.state.selectedMixer.name ||
      mixerError;

    return (
      <section className={styles.infoContainer}>
        <Dropdown
          selectedId={companyId}
          items={comps}
          placeholder={compsPlaceHolder}
          handleSelect={this.handleCompanySelection}
          label="Company"
          disableDropdown={comps.length === 0}
        />
        <Dropdown
          selectedId={plantId}
          items={plants}
          placeholder={plantsPlaceHolder}
          handleSelect={this.handlePlantSelection}
          label="Plants"
          disableDropdown={!this.state.selectedCompany || plants.length === 0}
        />
        <OptGroupDropdown
          selectedId={mixerId}
          items={mixerGroup}
          placeholder={mixerPlaceHolder}
          handleSelect={this.handleMixerSelect}
          label="Mixers"
          disableDropdown={!this.state.selectedCompany || !this.state.selectedPlant || mixerGroup.length === 0}
        />
        <section className={styles.submitRow}>
          <fieldset>
            <input
              type="text"
              name="mixerName"
              onChange={this.handleMixerNameChange}
              value={this.state.newMixerName}
              placeholder="Mixer Name"
              className={styles.mixerField}
              disabled={
                !this.state.selectedCompany ||
                !this.state.selectedPlant ||
                mixerGroup.length === 0 ||
                !this.state.selectedMixer
              }
            />
            {mixerError && <span className={styles.formError}>{this.state.errors.mixerName}</span>}
          </fieldset>
          <button onClick={this.assignMixer} disabled={disableAssignMixer} className={styles.buttonStyling}>
            Assign Mixer
          </button>
        </section>
        <section className={styles.pageHeaderContainer}>
          <h3>List of Mixers</h3>
        </section>
        <table className={styles.plantTable}>
          <thead>
            <tr>
              <th>Mixer ID</th>
              <th>Mixer Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.plantMixers.map(p => (
              <tr key={p.id}>
                <td>{p.externalId}</td>
                <td>{p.name}</td>
                <td>
                  <button className={styles.buttonStyling} onClick={this.unassignMixer} value={p.id}>
                    Unassign
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={this.state.totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={this.handlePageClick}
          activeClassName={styles.selected}
          disabledClassName={styles.disabled}
          containerClassName={styles.pageSelector}
          forcePage={this.state.currentPage}
        />
        <AlertModal
          show={this.state.showModal}
          message={'Do you want to unassign this mixer?'}
          handleYes={this.handleUnassign}
          handleNo={this.cancelUnassign}
        />
      </section>
    );
  }

  async getAllCompanies() {
    axios
      .get(`${window.env.API_URL}/api/v1/data/allCompanies`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.setState({
          isLoading: false,
          companies: response.data
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async getAllPlantsForComapny(company = this.state.selectedCompany) {
    axios
      .get(`${window.env.API_URL}/api/v1/admin/plantList/${company.id}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        const plants: Plant[] = data.map(p => {
          return {
            id: String(p.id),
            name: p.name,
            company: p.company.name
          };
        });
        this.setState({
          isLoading: false,
          plants: plants
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async getMixersForPlant(requestPage: number, plant = this.state.selectedPlant) {
    axios
      .get(`${window.env.API_URL}/api/v1/admin/mixers/${plant.id}`, {
        params: {
          page: requestPage,
          size: this.state.pageSize
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        const mixers: Mixer[] = data.content.map(m => {
          return {
            id: String(m.id),
            externalId: m.externalId,
            plant: m.plant.name,
            name: m.name
          };
        });
        this.setState({
          isLoading: false,
          plantMixers: mixers,
          totalPages: data.totalPages,
          currentPage: requestPage
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async getUnassignedMixers() {
    axios
      .get(`${window.env.API_URL}/api/v1/admin/mixersList/un-assign`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        const mixers: Mixer[] = data.content.map(m => {
          return {
            id: String(m.id),
            externalId: m.externalId,
            plant: 'Unassigned',
            name: m.name
          };
        });
        this.setState({
          isLoading: false,
          mixerList: mixers
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async getUnassignedAndPlantMixers(company = this.state.selectedCompany) {
    axios
      .all([
        axios.get(`${window.env.API_URL}/api/v1/admin/mixersList/un-assign`, {
          headers: {
            Authorization: 'Bearer ' + this.props.accessToken
          }
        }),
        axios.get(`${window.env.API_URL}/api/v1/admin/assignedMixers/${company.id}`, {
          headers: {
            Authorization: 'Bearer ' + this.props.accessToken
          }
        })
      ])
      .then(
        axios.spread((unassigned, assigned) => {
          const results = [...unassigned.data, ...assigned.data];
          const mixers: Mixer[] = results.map(m => {
            return {
              id: String(m.id),
              externalId: m.externalId,
              plant: m.plant ? m.plant.name : 'Unassigned',
              name: m.name
            };
          });
          this.setState({
            isLoading: false,
            mixerList: mixers
          });
        })
      )
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async assignMixerToPlant(plantId: string, mixerId: string, name: string) {
    axios
      .put(`${window.env.API_URL}/api/v1/admin/mixers/assign`, null, {
        params: {
          plantId: plantId,
          mixerId: mixerId,
          name: name
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.getMixersForPlant(0);
        this.getUnassignedAndPlantMixers();
        this.setState({ selectedMixer: null, newMixerName: '' });
      })
      .catch(error => {
        if (!error.response) {
          toast.error('Network Error');
        } else if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          if (error.response.data === 'Mixer name must be unique within the selected plant') {
            let errors = this.state.errors;
            errors.mixerName = 'Name must be unique within plant';
            this.setState({ errors });
          }
          toast.error(error.response.data);
        }
      });
  }

  async unassignMixerFromPlant(plantId: string, mixerId: string) {
    axios
      .put(`${window.env.API_URL}/api/v1/admin/mixers/un-assign`, null, {
        params: {
          plantId: plantId,
          mixerId: mixerId
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.getMixersForPlant(0);
        this.getUnassignedAndPlantMixers();
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }
}

export default withRouter(MixerManagement);
