import * as React from 'react';

const styles = require('./averageYieldMetric.scss');

class AverageYieldMetric extends React.Component<{ value: number }> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className={styles.yieldContainer}>
        <div className={styles.value}>{formatYieldValue(this.props.value)}</div>
        <div className={styles.valueLabel}>Average Yield Variance</div>
      </section>
    );
  }
}

function formatYieldValue(yieldValue: number) {
  return yieldValue ? yieldValue.toFixed(2) + '%' : 'N/A';
}

export default AverageYieldMetric;
