import React from 'react';

import DatePicker from 'react-datepicker';
import subYears from 'date-fns/subYears';
import addYears from 'date-fns/addYears';

import MultipleSelect from '../controls/chipMultiSelect';
import Dropdown from '../controls/dropdown';
import FilterDropdown from '../controls/filterDropDown';

import { MultiSelectItem, DropdownItem } from '../controls/dropdownItem';

import 'react-datepicker/dist/react-datepicker.css';

const styles = require('./filterHeader.scss');

interface Props {
  startDate: Date;
  endDate: Date;
  changeDateRange: (start: Date, end: Date) => void;
  companies: DropdownItem[];
  selectedCompany: string;
  plants: MultiSelectItem[];
  selectedPlants: MultiSelectItem[];
  mixers: MultiSelectItem[];
  selectedMixers: MultiSelectItem[];
  mixTypes: MultiSelectItem[];
  selectedmixTypes: MultiSelectItem[];
  handleCompanySelect: (event: React.FormEvent<HTMLSelectElement>) => void;
  handlePlantMultiSelect: (event: React.ChangeEvent<{ value: string[] }>) => void;
  handlePlantDeselect: (selectedId: string) => void;
  handleMixerMultiSelect: (event: React.ChangeEvent<{ value: string[] }>) => void;
  handleMixerDeselect: (selectedId: string) => void;
  handleMixTypeMultiSelect: (event: React.ChangeEvent<{ value: string[] }>) => void;
  handleMixTypeDeselect: (selectedId: string) => void;
  isGcpAdmin: boolean;
}

const FilterHeader: React.FunctionComponent<Props> = props => (
  <section className={`${styles.filterContainer} ${props.isGcpAdmin ? styles.adminFilters : ''}`}>
    <p className={styles.filterLabel}>Date Range</p>
    {props.isGcpAdmin && <p className={styles.filterLabel}>Companies</p>}
    <p className={styles.filterLabel}>Plants</p>
    <p className={styles.filterLabel}>Mixers</p>
    <p className={styles.filterLabel}>Mix Types</p>
    <div className={`${styles.filterSection} ${styles.dateFilter}`}>
      <DatePicker
        selected={props.startDate}
        onChange={date => props.changeDateRange(date, props.endDate)}
        startDate={props.startDate}
        endDate={props.endDate}
        maxDate={props.endDate}
        minDate={subYears(props.endDate, 1)}
      />
      &nbsp; - &nbsp;
      <DatePicker
        selected={props.endDate}
        onChange={date => props.changeDateRange(props.startDate, date)}
        endDate={props.endDate}
        minDate={props.startDate}
        maxDate={addYears(props.startDate, 1) > new Date() ? new Date() : addYears(props.startDate, 1)}
      />
    </div>
    {props.isGcpAdmin && (
      <div className={`${styles.filterSection} ${styles.companyFilter}`}>
        <Dropdown
          items={props.companies}
          selectedId={props.selectedCompany}
          handleSelect={props.handleCompanySelect}
          isFilter={true}
          placeholder={props.companies.length > 0 ? '' : 'No Available Companies'}
          disableDropdown={props.companies.length === 0}
        />
      </div>
    )}
    <div className={`${styles.filterSection} ${styles.plantFilter}`}>
      <MultipleSelect
        items={props.plants}
        selectedItems={props.selectedPlants}
        handleMultiSelect={props.handlePlantMultiSelect}
        handleDeselect={props.handlePlantDeselect}
        placeHolder={props.plants.length > 0 ? 'All Plants' : 'No Available Plants'}
      />
    </div>
    <div className={`${styles.filterSection} ${styles.mixerFilter}`}>
      <MultipleSelect
        items={props.mixers}
        selectedItems={props.selectedMixers}
        handleMultiSelect={props.handleMixerMultiSelect}
        handleDeselect={props.handleMixerDeselect}
        placeHolder={props.mixers.length > 0 ? 'All Mixers' : 'No Available Mixers'}
      />
    </div>
    <div className={`${styles.filterSection} ${styles.mixTypeFilter}`}>
      <MultipleSelect
        items={props.mixTypes}
        selectedItems={props.selectedmixTypes}
        handleMultiSelect={props.handleMixTypeMultiSelect}
        handleDeselect={props.handleMixTypeDeselect}
        placeHolder={props.mixTypes.length > 0 ? 'All Mix Types' : 'No Available Mix Types'}
      />
    </div>
  </section>
);

export default FilterHeader;
