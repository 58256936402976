import * as React from 'react';
import { toast } from 'react-toastify';
import { withRouter, RouteComponentProps } from 'react-router';

import axios from 'axios';
import qs from 'qs';

import Loading from '../controls/loading';

import AirContentMetric from './airContentMetric';
import StandardAirDeviationMetric from './standardAirDeviationMetric';
import TemperatureMetric from './temperatureMetric';

import ProductionTimeMetric from './productionTimeMetric';
import BatchNumberMetric from './batchNumberMetric';
import AverageYieldMetric from './averageYieldMetric';

import FilterHeader from '../filters/filterHeader';

import Interval from './interval';

import { Plant, Mixer } from '../management/management';

import { MultiSelectItem, DropdownItem } from '../controls/dropdownItem';

import 'react-toastify/dist/ReactToastify.css';

import startOfWeek from 'date-fns/startOfWeek';
import formatDate from 'date-fns/format';

const styles = require('./dashboard.scss');

interface QualityMetrics {
  standardAirDeviation: number;
  averageAirContent: number;
  percentBatchesInSpec: number;
  averageTemperature: number;
  lowestTemperature: number;
  highestTemperature: number;
}

interface ProductionMetrics {
  totalNumberOfBatches: number;
  batchesPerHour: number;
  averageDuration: Interval;
  averageWetMixTime: Interval;
  averageIdleTime: Interval;
  stdDevWetMixTimeInSec: number;
  maxDuration: Interval;
  averageYieldVariance: number;
}

interface Filters {
  startDate: Date;
  endDate: Date;
  plants: Plant[];
  selectedPlants: Plant[];
  mixers: Mixer[];
  selectedMixers: Mixer[];
  mixTypes: MultiSelectItem[];
  selectedMixTypes: MultiSelectItem[];
  companies?: DropdownItem[];
  selectedCompany?: string;
}

toast.configure({
  autoClose: 5000,
  position: 'top-center'
});

interface State {
  qualityMetrics: QualityMetrics;
  productionMetrics: ProductionMetrics;
  filters: Filters;
  isLoading: boolean;
}

interface Props extends RouteComponentProps {
  accessToken: string;
  isGcpAdmin: boolean;
}

class Dashboard extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const startDate = (props.location.state && props.location.state.startDate) || startOfWeek(new Date());
    const endDate = (props.location.state && props.location.state.endDate) || new Date();
    const selectedPlants = (props.location.state && props.location.state.selectedPlants) || [];
    const selectedMixers = (props.location.state && props.location.state.selectedMixers) || [];
    const selectedMixTypes = (props.location.state && props.location.state.selectedMixTypes) || [];
    const selectedCompany = (props.location.state && props.location.state.selectedCompany) || '';

    this.state = {
      qualityMetrics: {
        standardAirDeviation: 0,
        averageAirContent: 0,
        percentBatchesInSpec: 0,
        averageTemperature: 0,
        lowestTemperature: 0,
        highestTemperature: 0
      },
      productionMetrics: {
        totalNumberOfBatches: 0,
        batchesPerHour: 0,
        averageDuration: null,
        averageWetMixTime: null,
        averageIdleTime: null,
        stdDevWetMixTimeInSec: 0,
        maxDuration: null,
        averageYieldVariance: null
      },
      filters: {
        startDate: startDate,
        endDate: endDate,
        plants: [],
        selectedPlants: selectedPlants,
        mixers: [],
        selectedMixers: selectedMixers,
        mixTypes: [],
        selectedMixTypes: selectedMixTypes,
        companies: [],
        selectedCompany: selectedCompany
      },
      isLoading: true
    };

    this.getMetrics = this.getMetrics.bind(this);
    this.changeDateRange = this.changeDateRange.bind(this);
    this.selectPlants = this.selectPlants.bind(this);
    this.deselectPlant = this.deselectPlant.bind(this);
    this.selectCompany = this.selectCompany.bind(this);
  }

  componentDidMount() {
    this.loadDashboard();
  }

  render() {
    const noData: boolean = this.state.productionMetrics.totalNumberOfBatches == 0;
    return (
      <section className={styles.dashboardContainer}>
        <h2>Executive summary</h2>
        <FilterHeader
          changeDateRange={this.changeDateRange}
          startDate={this.state.filters.startDate}
          endDate={this.state.filters.endDate}
          plants={this.state.filters.plants}
          selectedPlants={this.state.filters.selectedPlants}
          handlePlantMultiSelect={this.selectPlants}
          handlePlantDeselect={this.deselectPlant}
          mixers={this.state.filters.mixers}
          selectedMixers={this.state.filters.selectedMixers}
          handleMixerMultiSelect={this.selectMixers}
          handleMixerDeselect={this.deselectMixer}
          mixTypes={this.state.filters.mixTypes}
          selectedmixTypes={this.state.filters.selectedMixTypes}
          handleMixTypeMultiSelect={this.selectMixTypes}
          handleMixTypeDeselect={this.deselectMixType}
          companies={this.state.filters.companies}
          selectedCompany={this.state.filters.selectedCompany}
          handleCompanySelect={this.selectCompany}
          isGcpAdmin={this.props.isGcpAdmin}
        />
        <section className={styles.metricsContainer}>
          {this.state.isLoading && <Loading />}
          <div className={styles.dashboardTitle}>Concrete Mix Quality & Production Data</div>
          <div className={styles.dailyView}>
            <button disabled={noData} onClick={this.viewDetails}>
              View Details
            </button>
          </div>
          <div className={styles.dashboardQualityTitle}>Concrete Mix Quality</div>
          <section className={styles.dashboardQualityCharts}>
            <AirContentMetric
              value={this.state.qualityMetrics.percentBatchesInSpec}
              avgValue={this.state.qualityMetrics.averageAirContent}
            />
            <StandardAirDeviationMetric value={this.state.qualityMetrics.standardAirDeviation} />
            <TemperatureMetric
              value={this.state.qualityMetrics.averageTemperature}
              lowTemp={this.state.qualityMetrics.lowestTemperature}
              highTemp={this.state.qualityMetrics.highestTemperature}
            />
          </section>
          <div className={styles.dashboardMixTitle}>Concrete Mix Production</div>
          <section className={styles.dashboardMixCharts}>
            <ProductionTimeMetric
              averageDuration={this.state.productionMetrics.averageDuration}
              averageWetMixTime={this.state.productionMetrics.averageWetMixTime}
              averageIdleTime={this.state.productionMetrics.averageIdleTime}
              stdDevWetMixTimeInSec={this.state.productionMetrics.stdDevWetMixTimeInSec}
              maxDuration={this.state.productionMetrics.maxDuration}
            />
            <BatchNumberMetric
              totalBatches={this.state.productionMetrics.totalNumberOfBatches}
              batchesPerHour={this.state.productionMetrics.batchesPerHour}
            />
            <AverageYieldMetric value={this.state.productionMetrics.averageYieldVariance} />
          </section>
        </section>
      </section>
    );
  }

  viewDetails = () => {
    this.props.history.push({
      pathname: '/daily-view',
      state: {
        startDate: this.state.filters.startDate,
        endDate: this.state.filters.endDate,
        selectedPlants: this.state.filters.selectedPlants,
        selectedMixers: this.state.filters.selectedMixers,
        selectedMixTypes: this.state.filters.selectedMixTypes,
        selectedCompany: this.state.filters.selectedCompany
      }
    });
  };

  changeDateRange = (start: Date, end: Date) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          startDate: start,
          endDate: end
        }
      },
      () => {
        this.getMetrics();
      }
    );
  };

  selectPlants = (event: React.ChangeEvent<{ value: string[] }>) => {
    const { value } = event.target;
    const allPlants = this.state.filters.plants;
    const filterPlants = allPlants.filter(p => value.indexOf(p.id) >= 0);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedPlants: filterPlants
        }
      },
      () => {
        this.getMixers().then(() => this.getMixTypes().then(() => this.getMetrics()));
      }
    );
  };

  deselectPlant = (selectedId: string) => {
    const filterPlants = this.state.filters.selectedPlants.filter(p => p.id !== selectedId);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedPlants: filterPlants
        }
      },
      () => {
        this.getMixers().then(() => this.getMixTypes().then(() => this.getMetrics()));
      }
    );
  };

  selectMixers = (event: React.ChangeEvent<{ value: string[] }>) => {
    const { value } = event.target;
    const allMixers = this.state.filters.mixers;
    const filterMixers = allMixers.filter(p => value.indexOf(p.id) >= 0);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedMixers: filterMixers
        }
      },
      () => {
        this.getMixTypes().then(() => this.getMetrics());
      }
    );
  };

  deselectMixer = (selectedId: string) => {
    const filterMixers = this.state.filters.selectedMixers.filter(p => p.id !== selectedId);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedMixers: filterMixers
        }
      },
      () => {
        this.getMixTypes().then(() => this.getMetrics());
      }
    );
  };

  selectMixTypes = (event: React.ChangeEvent<{ value: string[] }>) => {
    const { value } = event.target;
    const allMixTypes = this.state.filters.mixTypes;
    const filterMixerTypes = allMixTypes.filter(p => value.indexOf(p.id) >= 0);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedMixTypes: filterMixerTypes
        }
      },
      () => {
        this.getMetrics();
      }
    );
  };

  deselectMixType = (selectedId: string) => {
    const filterMixTypes = this.state.filters.selectedMixTypes.filter(p => p.id !== selectedId);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedMixTypes: filterMixTypes
        }
      },
      () => {
        this.getMetrics();
      }
    );
  };

  selectCompany = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          selectedPlants: [],
          selectedMixers: [],
          selectedMixTypes: [],
          selectedCompany: event.target.value
        }
      },
      () => {
        this.getMetrics();
        this.getPlants();
        this.getMixers();
        this.getMixTypes();
      }
    );
  };

  async loadDashboard() {
    if (this.props.isGcpAdmin) {
      await this.getCompanies();
      if (this.state.filters.companies.length > 0) {
        this.getMetrics();
        this.getPlants();
        this.getMixers();
        this.getMixTypes();
      }
    } else {
      this.getMetrics();
      this.getPlants();
      this.getMixers();
      this.getMixTypes();
    }
  }

  async getMetrics() {
    this.setState({ isLoading: true });
    const startDate: string = formatDate(this.state.filters.startDate, 'yyyy-MM-dd');
    const endDate: string = formatDate(this.state.filters.endDate, 'yyyy-MM-dd');
    let params = {
      startDate: startDate,
      endDate: endDate
    };

    if (this.props.isGcpAdmin) {
      params['company'] = this.state.filters.selectedCompany;
    }

    if (this.state.filters.selectedPlants.length > 0) {
      params['plants'] = this.state.filters.selectedPlants.map(plant => plant.id);
    }

    if (this.state.filters.selectedMixers.length > 0) {
      params['mixers'] = this.state.filters.selectedMixers.map(mixer => mixer.id);
    }

    if (this.state.filters.selectedMixTypes.length > 0) {
      params['mixIds'] = this.state.filters.selectedMixTypes.map(mix => mix.name);
    }

    axios
      .get(`${window.env.API_URL}/api/v1/data/metrics`, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.setState({
          qualityMetrics: {
            standardAirDeviation: response.data.standardAirDeviation,
            averageAirContent: response.data.averageAirContent,
            percentBatchesInSpec: response.data.percentBatchesInSpec,
            averageTemperature: response.data.averageTemperature,
            lowestTemperature: response.data.lowestTemperature,
            highestTemperature: response.data.highestTemperature
          },
          productionMetrics: {
            totalNumberOfBatches: response.data.totalNumberOfBatches,
            batchesPerHour: response.data.batchesPerHour,
            averageDuration: response.data.averageDuration,
            averageWetMixTime: response.data.averageWetMixTime,
            averageIdleTime: response.data.averageIdleTime,
            stdDevWetMixTimeInSec: response.data.stdDevWetMixTimeInSec,
            maxDuration: response.data.maxDuration,
            averageYieldVariance: response.data.averageYieldVariance
          },
          isLoading: false
        });
      })
      .catch(error => {
        const errorMessage: string =
          error.response && error.response.status === 403 ? error.response.data : error.message;
        if (!toast.isActive(errorMessage)) {
          toast.error(errorMessage, { toastId: errorMessage });
        }
        this.setState({ isLoading: false });
      });
  }

  async getPlants() {
    const plants = this.props.isGcpAdmin ? 'plantList/' + this.state.filters.selectedCompany : 'plantList';
    await axios
      .get(`${window.env.API_URL}/api/v1/admin/${plants}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        this.setState({
          filters: {
            ...this.state.filters,
            plants: data
          }
        });
      })
      .catch(error => {
        const errorMessage: string =
          error.response && error.response.status === 403 ? error.response.data : error.message;
        if (!toast.isActive(errorMessage)) {
          toast.error(errorMessage, { toastId: errorMessage });
        }
      });
  }

  async getMixers() {
    let params = {};

    if (this.props.isGcpAdmin) {
      params['company'] = this.state.filters.selectedCompany;
    }

    if (this.state.filters.selectedPlants.length > 0) {
      params['plants'] = this.state.filters.selectedPlants.map(plant => plant.id);
    }

    await axios
      .get(`${window.env.API_URL}/api/v1/admin/mixersList`, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        const responseMixersId = data.map(m => m.id);
        const filteredMixers = this.state.filters.selectedMixers.filter(m => responseMixersId.indexOf(m.id) >= 0);
        this.setState({
          filters: {
            ...this.state.filters,
            mixers: data,
            selectedMixers: filteredMixers
          }
        });
      })
      .catch(error => {
        const errorMessage: string =
          error.response && error.response.status === 403 ? error.response.data : error.message;
        if (!toast.isActive(errorMessage)) {
          toast.error(errorMessage, { toastId: errorMessage });
        }
      });
  }

  async getMixTypes() {
    let params = {};

    if (this.props.isGcpAdmin) {
      params['company'] = this.state.filters.selectedCompany;
    }

    if (this.state.filters.selectedPlants.length > 0) {
      params['plants'] = this.state.filters.selectedPlants.map(plant => plant.id);
    }

    if (this.state.filters.selectedMixers.length > 0) {
      params['mixers'] = this.state.filters.selectedMixers.map(mixer => mixer.id);
    }

    await axios
      .get(`${window.env.API_URL}/api/v1/admin/mixList`, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        const mixTypes: MultiSelectItem[] = [];
        data.forEach((d, index) => {
          mixTypes.push({ id: index, name: d });
        });
        const responseMixerTypes = mixTypes.map(m => m.id);
        const filteredMixerTypes = this.state.filters.selectedMixTypes.filter(
          m => responseMixerTypes.indexOf(m.id) >= 0
        );
        this.setState({
          filters: {
            ...this.state.filters,
            mixTypes: mixTypes,
            selectedMixTypes: filteredMixerTypes
          }
        });
      })
      .catch(error => {
        const errorMessage: string =
          error.response && error.response.status === 403 ? error.response.data : error.message;
        if (!toast.isActive(errorMessage)) {
          toast.error(errorMessage, { toastId: errorMessage });
        }
      });
  }

  async getCompanies() {
    await axios
      .get(`${window.env.API_URL}/api/v1/data/allCompanies`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        // Check to see if there are any companies. If there are companies then proceed to set the filters.
        if (data.length > 0) {
          const companies: DropdownItem[] = data.map(c => {
            return {
              id: String(c.id),
              text: c.name
            };
          });

          // If user hasn't selected a company to filter by, or the filters get lost when navigating, the first company will be selected by default
          const selectedCompany = this.state.filters.selectedCompany
            ? this.state.filters.selectedCompany
            : companies[0].id;
          this.setState({
            filters: {
              ...this.state.filters,
              companies: companies,
              selectedCompany: selectedCompany
            }
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        const errorMessage: string =
          error.response && error.response.status === 403 ? error.response.data : error.message;
        if (!toast.isActive(errorMessage)) {
          toast.error(errorMessage, { toastId: errorMessage });
        }
        this.setState({ isLoading: false });
      });
  }
}

export default withRouter(Dashboard);
