import * as React from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';

import Loading from '../controls/loading';

import MixQualityGraph from './mixQualityGraph';
import MixProductionGraph from './mixProductionGraph';

import { Plant, Mixer } from '../management/management';

import axios from 'axios';
import qs from 'qs';

import 'react-toastify/dist/ReactToastify.css';
import startOfWeek from 'date-fns/startOfWeek';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { saveAs } from 'file-saver';

import { DailyQualitySpecs } from './dailyQualitySpecs';
import { DailyProductionSpecs } from './dailyProductionSpecs';
import Interval from '../dashboard/interval';
import { MultiSelectItem } from '../controls/dropdownItem';
import ExportButton from '../controls/exportButton';

const styles = require('./dailyView.scss');

toast.configure({
  autoClose: 5000,
  position: 'top-center'
});

interface Filters {
  startDate: Date;
  endDate: Date;
  selectedPlants: Plant[];
  selectedMixers: Mixer[];
  selectedMixTypes: MultiSelectItem[];
  selectedCompany: string;
}

interface State {
  dailyQualitySpecs: DailyQualitySpecs;
  dailyProductionSpecs: DailyProductionSpecs;
  date: string[];
  filters: Filters;
  isLoading: boolean;
  isExporting: boolean;
}

interface Props extends RouteComponentProps {
  accessToken: string;
  isGcpAdmin: boolean;
}

class DailyView extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const startDate = (props.location.state && props.location.state.startDate) || startOfWeek(new Date());
    const endDate = (props.location.state && props.location.state.endDate) || new Date();
    const selectedPlants = (props.location.state && props.location.state.selectedPlants) || [];
    const selectedMixers = (props.location.state && props.location.state.selectedMixers) || [];
    const selectedMixTypes = (props.location.state && props.location.state.selectedMixTypes) || [];
    const selectedCompany = (props.location.state && props.location.state.selectedCompany) || '';

    this.state = {
      dailyQualitySpecs: {
        airPercent: [],
        averageAirContent: [],
        standardAirDeviation: [],
        averageTemperature: [],
        minTemperature: [],
        maxTemperature: [],
        numberOfBatches: []
      },
      dailyProductionSpecs: {
        batchesPerHour: [],
        numberOfBatches: [],
        averageYieldVariance: [],
        batchDuration: [],
        wetMixTime: [],
        stdDevWetMixTime: [],
        idleTime: []
      },
      date: [],
      filters: {
        startDate: startDate,
        endDate: endDate,
        selectedPlants: selectedPlants,
        selectedMixers: selectedMixers,
        selectedMixTypes: selectedMixTypes,
        selectedCompany: selectedCompany
      },
      isLoading: true,
      isExporting: false
    };
  }

  componentDidMount() {
    this.loadDailyView();
  }

  async loadDailyView() {
    if (this.props.isGcpAdmin && !this.state.filters.selectedCompany) {
      await this.getCompany();
      if (this.state.filters.selectedCompany) {
        this.getDailyViewData();
      }
    } else {
      this.getDailyViewData();
    }
  }

  render() {
    return (
      <section className={styles.dailyViewContainer}>
        <section className={styles.breadcrumbs}>
          <NavLink
            to={{
              pathname: '/dashboard',
              state: {
                startDate: this.state.filters.startDate,
                endDate: this.state.filters.endDate,
                selectedPlants: this.state.filters.selectedPlants,
                selectedMixers: this.state.filters.selectedMixers,
                selectedMixTypes: this.state.filters.selectedMixTypes,
                selectedCompany: this.state.filters.selectedCompany
              }
            }}
            className={styles.dailyView}
          >
            <span>Executive Summary</span>
          </NavLink>
          <span> > </span>
          <span>Quality & Production</span>
        </section>
        <section className={styles.sectionHeader}>
          <h2>Quality & Production Day by Day</h2>
          <ExportButton
            accessToken={this.props.accessToken}
            isGcpAdmin={this.props.isGcpAdmin}
            filters={this.state.filters}
          />
        </section>
        <section className={styles.graphContainer}>
          {this.state.isLoading && <Loading />}
          <MixQualityGraph
            dailyQualitySpecs={this.state.dailyQualitySpecs}
            dates={this.state.date}
            handlePointClick={this.toDayView}
          />
          <MixProductionGraph
            dailyProductionSpecs={this.state.dailyProductionSpecs}
            dates={this.state.date}
            handlePointClick={this.toDayView}
          />
        </section>
      </section>
    );
  }

  toDayView = (date: string) => {
    this.props.history.push({
      pathname: '/day-view',
      state: {
        selectedDate: date,
        startDate: this.state.filters.startDate,
        endDate: this.state.filters.endDate,
        selectedPlants: this.state.filters.selectedPlants,
        selectedMixers: this.state.filters.selectedMixers,
        selectedMixTypes: this.state.filters.selectedMixTypes,
        selectedCompany: this.state.filters.selectedCompany
      }
    });
  };

  async getDailyViewData() {
    let params = {
      startDate: formatDate(this.state.filters.startDate, 'yyyy-MM-dd'),
      endDate: formatDate(this.state.filters.endDate, 'yyyy-MM-dd')
    };

    if (this.props.isGcpAdmin) {
      params['company'] = this.state.filters.selectedCompany;
    }

    if (this.state.filters.selectedPlants.length > 0) {
      params['plants'] = this.state.filters.selectedPlants.map(plant => plant.id);
    }

    if (this.state.filters.selectedMixers.length > 0) {
      params['mixers'] = this.state.filters.selectedMixers.map(mixer => mixer.id);
    }

    if (this.state.filters.selectedMixTypes.length > 0) {
      params['mixIds'] = this.state.filters.selectedMixTypes.map(mix => mix.name);
    }

    axios
      .get(`${window.env.API_URL}/api/v1/data/dailyView`, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const dates = [];
        const percent = [];
        const averageAirContent = [];
        const standardAirDev = [];
        const avgTemp = [];
        const minTemperature = [];
        const maxTemperature = [];
        const numBatches = [];
        const batchesPerHour = [];
        const averageYieldVariance = [];
        const batchDuration: Interval[] = [];
        const wetMixTime: Interval[] = [];
        const stdDevWetMixTime: number[] = [];
        const idleTime: Interval[] = [];
        response.data.forEach(d => {
          if (!!d.dashboardMetrics.totalNumberOfBatches && d.dashboardMetrics.totalNumberOfBatches > 0) {
            dates.push(parseISO(d.viewDate));
            averageAirContent.push(d.dashboardMetrics.averageAirContent);
            standardAirDev.push(d.dashboardMetrics.standardAirDeviation);
            minTemperature.push(d.dashboardMetrics.lowestTemperature);
            maxTemperature.push(d.dashboardMetrics.highestTemperature);
            numBatches.push(d.dashboardMetrics.totalNumberOfBatches);
            percent.push(d.dashboardMetrics.percentBatchesInSpec);
            avgTemp.push(d.dashboardMetrics.averageTemperature);
            batchesPerHour.push(d.dashboardMetrics.batchesPerHour);
            averageYieldVariance.push(d.dashboardMetrics.averageYieldVariance);
            batchDuration.push(d.dashboardMetrics.averageDuration);
            wetMixTime.push(d.dashboardMetrics.averageWetMixTime);
            stdDevWetMixTime.push(d.dashboardMetrics.stdDevWetMixTimeInSec);
            idleTime.push(d.dashboardMetrics.averageIdleTime);
          }
        });
        this.setState({
          dailyQualitySpecs: {
            airPercent: percent,
            averageAirContent: averageAirContent,
            standardAirDeviation: standardAirDev,
            averageTemperature: avgTemp,
            minTemperature: minTemperature,
            maxTemperature: maxTemperature,
            numberOfBatches: numBatches
          },
          dailyProductionSpecs: {
            batchesPerHour: batchesPerHour,
            numberOfBatches: numBatches,
            averageYieldVariance: averageYieldVariance,
            batchDuration: batchDuration,
            wetMixTime: wetMixTime,
            stdDevWetMixTime: stdDevWetMixTime,
            idleTime: idleTime
          },
          date: dates,
          isLoading: false
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          if (!toast.isActive(error.response.data)) {
            toast.error(error.response.data, { toastId: error.response.data });
          }
        } else {
          if (!toast.isActive(error.message)) {
            toast.error(error.message, { toastId: error.message });
          }
        }
        this.setState({ isLoading: false });
      });
  }

  async getCompany() {
    await axios
      .get(`${window.env.API_URL}/api/v1/data/allCompanies`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        const { data } = response;
        if (data.length > 0) {
          this.setState({
            filters: {
              ...this.state.filters,
              selectedCompany: String(data[0].id)
            }
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          if (!toast.isActive(error.response.data)) {
            toast.error(error.response.data, { toastId: error.response.data });
          }
        } else {
          if (!toast.isActive(error.message)) {
            toast.error(error.message, { toastId: error.message });
          }
        }
        this.setState({ isLoading: false });
      });
  }
}

export default withRouter(DailyView);
