import React from 'react';
import ReactPaginate from 'react-paginate';
import { withRouter, RouteComponentProps } from 'react-router';

import axios from 'axios';

import { toast } from 'react-toastify';

import Dropdown from '../controls/dropdown';
import { DropdownItem } from '../controls/dropdownItem';
import AlertModal from '../controls/alertModal';
import InputModal from '../controls/inputModal';
import { Company, Plant } from './management';

const styles = require('./plantManagement.scss');

toast.configure({
  autoClose: 5000,
  position: 'top-center'
});

interface FormState {
  plantName: string;
  errors: {
    plantName: string;
  };
}

class NewPlantForm extends React.Component<
  { companyId: string; onSubmit: (plantName: string) => void; disabled: boolean },
  FormState
> {
  constructor(props) {
    super(props);

    this.state = {
      plantName: '',
      errors: {
        plantName: ''
      }
    };
  }

  componentDidUpdate(prevProps) {
    // Resets input if the selected company changes
    if (this.props.companyId !== prevProps.companyId) {
      this.setState({ plantName: '' });
    }
  }

  handlePlantNameChange = event => {
    const { value } = event.target;
    let errors = this.state.errors;

    if (value.trim().length == 0) {
      errors.plantName = 'Please enter in a plant name';
    } else if (value.trim().length > 64) {
      errors.plantName = 'Plant name longer than 64 characters';
    } else {
      errors.plantName = '';
    }

    this.setState({
      plantName: value,
      errors
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    let errors = this.state.errors;
    if (!this.state.plantName) {
      errors.plantName = 'Please enter in a plant name';
      this.setState({
        errors
      });
      return;
    }
    this.props.onSubmit(this.state.plantName.trim());
    this.setState({ plantName: '' });
  };

  render() {
    const plantError = this.state.errors.plantName.length > 0 || !this.state.plantName;
    return (
      <section>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            name="plantName"
            onChange={this.handlePlantNameChange}
            value={this.state.plantName}
            placeholder="Add New Plant"
            disabled={this.props.disabled}
          />
          <button disabled={plantError}>Submit</button>
        </form>
        {plantError && <span className={styles.formError}>{this.state.errors.plantName}</span>}
      </section>
    );
  }
}

interface Props extends RouteComponentProps {
  accessToken: string;
  isCompanyAdmin: boolean;
}

interface State {
  isLoading: boolean;
  companies: Company[];
  plants: Plant[];
  pageSize: number;
  currentPage: number;
  totalPages: number;
  selectedCompany: Company;
  showDeleteModal: boolean;
  deleteId: string;
  showEditModal: boolean;
  editId: string;
  editPlantName: string;
  editPlantNameErrorMessage: string;
  originalPlantName: string;
}

class PlantManagement extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      companies: [],
      plants: [],
      pageSize: 10,
      currentPage: 0,
      totalPages: 0,
      selectedCompany: null,
      showDeleteModal: false,
      deleteId: null,
      showEditModal: false,
      editId: null,
      editPlantName: null,
      editPlantNameErrorMessage: null,
      originalPlantName: null
    };

    this.addPlantGcpAdmin = this.addPlantGcpAdmin.bind(this);
    this.addPlantCompanyAdmin = this.addPlantCompanyAdmin.bind(this);
    this.getAllCompanies = this.getAllCompanies.bind(this);
    this.getPlantsGcpAdmin = this.getPlantsGcpAdmin.bind(this);
    this.getPlantsCompanyAdmin = this.getPlantsCompanyAdmin.bind(this);
    this.handleCompanySelection = this.handleCompanySelection.bind(this);

    this.deletePlant = this.deletePlant.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);

    this.editPlant = this.editPlant.bind(this);
    this.handleAcceptEdit = this.handleAcceptEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handlePlantNameChange = this.handlePlantNameChange.bind(this);
  }

  componentDidMount() {
    if (this.props.isCompanyAdmin) {
      this.getPlantsCompanyAdmin();
    } else {
      this.getAllCompanies();
    }
  }

  redirectUnauthorizedUser = () => {
    this.props.history.push({
      pathname: '/dashboard'
    });
  };

  handlePageClick = page => {
    if (this.props.isCompanyAdmin) {
      this.getPlantsCompanyAdmin(page.selected);
    } else {
      this.getPlantsGcpAdmin(page.selected);
    }
  };

  handleCompanySelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const company: Company = {
      id: Number(event.target.value),
      name: event.target.name
    };
    this.setState({ selectedCompany: company });
    this.getPlantsGcpAdmin(0, company);
  };

  deletePlant = event => {
    this.setState({
      showDeleteModal: true,
      deleteId: event.target.value
    });
  };

  handleDelete = () => {
    this.deletePlantFromCompany(this.state.deleteId);
    this.setState({
      showDeleteModal: false,
      deleteId: null
    });
  };

  cancelDelete = () => {
    this.setState({
      showDeleteModal: false,
      deleteId: null
    });
  };

  editPlant = (plant: Plant) => {
    this.setState({
      showEditModal: true,
      editId: plant.id,
      editPlantName: plant.name,
      editPlantNameErrorMessage: null,
      originalPlantName: plant.name
    });
  };

  handleAddNewPlant = (plantName: string) => {
    if (this.props.isCompanyAdmin) {
      this.addPlantCompanyAdmin(plantName);
    } else {
      this.addPlantGcpAdmin(plantName);
    }
  };

  handleAcceptEdit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const name: string = this.state.editPlantName.trim();

    this.editPlantRequest(this.state.editId, name);
    this.setState({
      showEditModal: false,
      editId: null
    });
  };

  cancelEdit = () => {
    this.setState({
      showEditModal: false,
      editId: null,
      editPlantName: null,
      editPlantNameErrorMessage: null
    });
  };

  handlePlantNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = event.currentTarget.value;
    let error: string = null;

    if (name.trim().length == 0) {
      error = 'Please enter in a plant name';
    } else if (name.trim().length > 64) {
      error = 'Plant name longer than 64 characters';
    }

    this.setState({
      editPlantName: name,
      editPlantNameErrorMessage: error
    });
  };

  render() {
    if (this.state.isLoading) return null;
    const { isCompanyAdmin } = this.props;
    const companyId: string = this.state.selectedCompany ? String(this.state.selectedCompany.id) : '';
    const comps: DropdownItem[] = [];

    if (!isCompanyAdmin) {
      this.state.companies.forEach(c => {
        comps.push({ id: String(c.id), text: c.name });
      });
    }
    const compsPlaceHolder = comps.length === 0 ? 'There are no Companies to Select' : 'Select a Company';
    
    return (
      <section>
        {!isCompanyAdmin && (
          <Dropdown
            selectedId={companyId}
            items={comps}
            placeholder={compsPlaceHolder}
            handleSelect={this.handleCompanySelection}
            label="Company"
            disableDropdown={comps.length === 0}
          />
        )}
        <section className={styles.pageHeaderContainer}>
          <h3>List of Plants</h3>
          <NewPlantForm
            companyId={companyId}
            onSubmit={this.handleAddNewPlant}
            disabled={!this.state.selectedCompany && !isCompanyAdmin}
          />
        </section>
        <table className={styles.plantTable}>
          <thead>
            <tr>
              <th>Plant ID</th>
              <th>Plant Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.plants.map(p => (
              <tr key={p.id}>
                <td>{p.id}</td>
                <td>{p.name}</td>
                <td>
                  <button className={styles.buttonStyling} onClick={() => this.editPlant(p)} value={p.id}>
                    Edit
                  </button>
                  <button className={styles.buttonStyling} onClick={this.deletePlant} value={p.id}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={this.state.totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={this.handlePageClick}
          activeClassName={styles.selected}
          disabledClassName={styles.disabled}
          containerClassName={styles.pageSelector}
          forcePage={this.state.currentPage}
        />
        <AlertModal
          show={this.state.showDeleteModal}
          message={'Are you sure you want to delete this plant?'}
          handleYes={this.handleDelete}
          handleNo={this.cancelDelete}
        />
        <InputModal
          inputValue={this.state.editPlantName}
          originalValue={this.state.originalPlantName}
          handleOk={this.handleAcceptEdit}
          handleCancel={this.cancelEdit}
          handleInputChange={this.handlePlantNameChange}
          show={this.state.showEditModal}
          errorMessage={this.state.editPlantNameErrorMessage}
          title={'Edit Plant Name'}
          placeHolder={'Enter a Plant Name'}
        />
      </section>
    );
  }

  async addPlantGcpAdmin(plantName: string, company = this.state.selectedCompany) {
    axios
      .post(
        `${window.env.API_URL}/api/v1/admin/plants/${company.id}`,
        {
          name: plantName
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.accessToken
          }
        }
      )
      .then(() => {
        toast.success(`You have successfully added ${plantName}`);
        this.getPlantsGcpAdmin(0);
      })
      .catch(error => {
        if (!error.response) {
          toast.error('Network Error');
        } else if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else if (error.response) {
          toast.error(error.response.data);
        } else {
          toast.error('Unexpected error');
        }
      });
  }

  async getPlantsGcpAdmin(requestPage, company = this.state.selectedCompany) {
    axios
      .get(`${window.env.API_URL}/api/v1/admin/plants/${company.id}`, {
        params: {
          page: requestPage,
          size: this.state.pageSize
        },
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.setState({
          isLoading: false,
          plants: response.data.content,
          totalPages: response.data.totalPages,
          currentPage: requestPage
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async getAllCompanies() {
    axios
      .get(`${window.env.API_URL}/api/v1/data/allCompanies`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.setState({
          isLoading: false,
          companies: response.data
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }

  async deletePlantFromCompany(plantId: string) {
    axios
      .delete(`${window.env.API_URL}/api/v1/admin/plants/${plantId}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(() => {
        toast.success('Successfully deleted plant');
        if (this.props.isCompanyAdmin) {
          this.getPlantsCompanyAdmin(0);
        } else {
          this.getPlantsGcpAdmin(0);
        }
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data);
        } else {
          toast.error(error.message);
        }
      });
  }

  async editPlantRequest(plantId: string, plantName: string) {
    axios
      .put(
        `${window.env.API_URL}/api/v1/admin/plants/`,
        {
          id: plantId,
          name: plantName
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.accessToken
          }
        }
      )
      .then(() => {
        toast.success('Successfully edited plant name');
        if (this.props.isCompanyAdmin) {
          this.getPlantsCompanyAdmin(0);
        } else {
          this.getPlantsGcpAdmin(0);
        }
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data);
        } else {
          toast.error(error.message);
        }
      });
  }

  async addPlantCompanyAdmin(plantName: string) {
    axios
      .post(
        `${window.env.API_URL}/api/v1/admin/companyAdmin/plants/`,
        {
          name: plantName
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.accessToken
          }
        }
      )
      .then(() => {
        toast.success(`You have successfully added ${plantName}`);
        this.getPlantsCompanyAdmin(0);
      })
      .catch(error => {
        if (!error.response) {
          toast.error('Network Error');
        } else if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else if (error.response) {
          toast.error(error.response.data);
        } else {
          toast.error('Unexpected error');
        }
      });
  }

  async getPlantsCompanyAdmin(requestPage = 0) {
    axios
      .get(`${window.env.API_URL}/api/v1/admin/companyAdmin/plants?page=${requestPage}&size=${this.state.pageSize}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.accessToken
        }
      })
      .then(response => {
        this.setState({
          isLoading: false,
          plants: response.data.content,
          totalPages: response.data.totalPages,
          currentPage: requestPage
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.redirectUnauthorizedUser();
        } else {
          toast.error(error.message);
        }
      });
  }
}

export default withRouter(PlantManagement);
