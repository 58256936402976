import React from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import { MultiSelectItem } from './dropdownItem';

const styles = require('./dropdown.scss');

interface DropdownProps {
  items: MultiSelectItem[];
  selectedItems: MultiSelectItem[];
  handleMultiSelect: (event: React.ChangeEvent<{ value: string[] }>) => void;
  handleDeselect: (selectedId: string) => void;
  placeHolder?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const defaultProps: Partial<DropdownProps> = {
  placeHolder: 'Select Options'
};

const MultipleSelect: React.FunctionComponent<DropdownProps> = props => (
  <div className={styles.dropdown}>
    <FormControl className={styles.formControlFilter}>
      <Select
        multiple
        displayEmpty
        value={props.selectedItems.map(plant => plant.id)}
        onChange={props.handleMultiSelect}
        input={<Input id="select-multiple-chip" />}
        renderValue={() => {
          if (props.selectedItems.length === 0) {
            return <em className={styles.placeHolder}>{props.placeHolder}</em>;
          } else {
            return (
              <div>
                {props.selectedItems.map(p => (
                  <Chip
                    key={p.id}
                    label={p.name}
                    className={styles.chips}
                    onDelete={() => props.handleDeselect(p.id)}
                  />
                ))}
              </div>
            );
          }
        }}
        MenuProps={MenuProps}
        className={styles.chipInput}
        disabled={props.items.length === 0}
      >
        {props.items.map(p => (
          <MenuItem key={p.id} value={p.id}>
            {p.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

MultipleSelect.defaultProps = defaultProps;

export default MultipleSelect;
